import React from 'react';
import style from './Style.module.css';
import Util from '../../Util';
import mag1 from '../../asset/mag1.svg';
import mag2 from '../../asset/mag2.svg';
import SellSheet from '../../Components/ShopSheetNew';

class Quicksearch extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			search: "",
			sheets:[]
		};
	}

	async componentDidMount() {
		this.search();
	}

	render() {
		console.log(this.state)
		return (
			<div className={style.Container}>
				<div className={style.searchBox}>
					<div className={style.mag}>
						<img src={mag1} className={style.mag1} />
						<img src={mag2} className={style.mag2} />
					</div>
					<input placeholder='วิชา' className={style.textBox} type="text" onChange={this.search} value={this.state.search} />
				</div>
				<div className={style.searchSheets}>
					{this.renderStuff()}
				</div>
			</div>
		);
	}

	search = async(event) => {
		var search = event? event.currentTarget.value: "";
		this.setState({search});
		var data = await Util.sendPost('/api/public/searchSheetTop', {search});
		this.setState({sheets:data.data});
	}

	renderStuff = () => {
		return this.state.sheets.map((sheet) => {
			return (
				<SellSheet sheet={sheet} key={sheet._id} store={{}} addCart={this.addCart}>
				</SellSheet>
			)
		}
		);
	}
}

export default Quicksearch;