import React from "react";
import { Link, Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import style from "./Style.module.css";
import org from "../../asset/org.jpg";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
//import './style.css';
const monthName = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];
class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      out: [],
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        {this.state.out.map((data, i) => {
          return this.renderPage(data, i);
        })}
      </React.Fragment>
    );
  }

  renderPage = (data, index) => {
    if (data.name.includes("ค่าทำ") && parseFloat(data.total) > 1000)
      data.total = ((parseFloat(data.total) * 100) / 98.5)
        .toFixed(2)
        .toString();
    return (
      <div className={style.page} key={index}>
        <div className={style.header}>ใบรับรองแทนใบเสร็จรับเงิน</div>
        <div className={style.rightText}>เลขที่ M4-{index}</div>
        <div className={style.rightText}>วันที่ {data.date}</div>

        <div className={style.topRow}>
          <div className={style.tr1}>วัน เดือน ปี</div>
          <div className={style.tr2}>รายละเอียดรายจ่าย</div>
          <div className={style.tr3}>จำนวนเงิน (บาท)</div>
          <div className={style.tr4}>หมายเหตุ</div>
        </div>

        <div className={style.dataRow}>
          <div className={style.td1}>{data.date}</div>
          <div className={style.td2}>{data.name}</div>
          <div className={style.td3}>{data.total.split(".")[0]}</div>
          <div className={style.td4}>.{data.total.split(".")[1]}</div>
          <div className={style.td5}></div>
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>

        <div className={style.preRow}>
          <div className={style.tp1}>รวมทั้งสิ้น</div>
          <div className={style.tp2}>{data.total.split(".")[0]}</div>
          <div className={style.tp3}>.{data.total.split(".")[1]}</div>
        </div>

        <div className={style.lastRow}>
          ตัวอักษร ({Util.bahttext(data.total)})
        </div>

        <div className={style.downtext1}>
          ข้าพเจ้า นายอดิเทพ ปัสรีจา (ผู้เบิกจ่าย) ตำแหน่ง (กรรมการบริษัท)
          ขอรับรองว่ารายจ่ายข้างต้นนี้ ไม่อาจเรียกใบเสร็จรับเงินจากผู้รับได้
          และข้าพเจ้าได้จ่ายไปในงานของทาง บริษัท มอร์ เซอร์วิสเซส จำกัด โดยแท้
          ตั้งแต่วันที่ {data.date} ถึงวันที่ {data.date}
        </div>

        <div className={style.righttextname3}>
          ลงชื่อ <b>อดิเทพ ปัสรีจา</b> (ผู้เบิกจ่าย)
        </div>
        <div className={style.righttextname4}>(นายอดิเทพ ปัสรีจา)</div>

        <div className={style.righttextname}>
          ลงชื่อ <b>ภูมิไผท พันธิตพงษ์</b> (ผู้อนุมัติ)
        </div>
        <div className={style.righttextname2}>(นายภูมิไผท พันธิตพงษ์)</div>

        <div className={style.downtext}>สำหรับบัญชี</div>
        <div className={style.downtext}>
          จ่ายผ่านการ<b>โอนเงิน</b>เมื่อวันที่ {data.date}
        </div>
        <div className={style.downtextlast}>
          **เอกสารนี้ใช้ทดแทนเอกสารที่ไม่สามารถได้รับใบเสร็จรับเงินได้
          สำหรับผู้ขายหรือผู้ให้บริการที่ไม่อยู่ในระบบภาษีมูลค่าเพิ่มเพื่อใช้เป็นเอกสารประกอบใบเบิกเงินที่สำรองจ่ายไปก่อน
        </div>
      </div>
    );
  };

  getNextMonth = () => {
    if (this.state.month + 1 > 12) return "01";
    else {
      if (this.state.month + 1 < 10)
        return "0" + (this.state.month + 1).toString();
      else return this.state.month + 1;
    }
  };

  getNextYear = () => {
    if (this.state.month + 1 > 12) return this.state.year + 544;
    else return this.state.year + 543;
  };
}

export default withRouter(withAlert()(EditSheet));
