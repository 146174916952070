import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import MySheet from '../../Components/MySheet';
import style from './Style.module.css';
import { Link , Redirect } from 'react-router-dom';
import {Container,Hidden,Visible} from 'react-grid-system';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			data:{
				user:{
					infoEnter:true,
				},
				messages:[],
			},
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/user/getMessage',{});
		if(data.success)
		{
			await this.setState({data:data.data});
		}
		else
			this.props.history.push('/');
	}

	render() {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				<div className={style.shopPage}>
					<Nav user={this.state.data.user} ale={true}/>
					<Container>
						<div className={style.blanker} />
						<div className={style.bigHeader}>ข้อความแจ้งเตือน</div>
						<hr />
						{this.renderMessage()}
					</Container>
				</div>
				<Footer />
			</React.Fragment>
		);
	}
	
	renderMessage() {
		return this.state.data.messages.map((message)=>{
			return (<div key={message._id} className={style['messageBox']+' '+style['box'+message.type]}>
						<div className={style.content} dangerouslySetInnerHTML={{__html:message.message}}></div>
						<div className={style.timein}>{this.parseISOString(message.created)}</div>
					</div>);
		})
	}
	
	parseISOString=(s)=> {
		var b = s.split(/\D+/);
		var created=new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
		return created.getDate()+'/'+(created.getMonth()+1)+'/'+created.getFullYear()+' '+created.getHours()+' นาฬิกา '+created.getMinutes()+' นาที';
	}
}

export default withRouter(withAlert()(Sell));