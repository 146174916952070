import React from 'react';
import { Link , Redirect } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import Util from '../../Util';
import FileUpload from '../../Components/FileUpload';
import style from './Style.module.css';
import './style.css';
import {Container} from 'react-grid-system';


class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		var x = new Date();
		this.state = {
			loading:false,
			redirect:false,
			data:{
				user:{},
				stores:[]
			},
			sheet:false,
			store:-1,
			count:0,
			month:x.getMonth()+1,
			year:x.getFullYear(),
			payData:[],
			total:0,
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/moresheetStaff/getPaymentMeta',{});
		if(!data.success)
			await this.setState({redirect:true});
		await this.setState({data:data.data});
		await this.setState({store:data.data.stores[0].code})
	}

	render() {
		return (
			<div className={style.sheetPage}>
				{this.state.redirect && <Redirect to='/staff/' />}
				<Container>
					<div className={style.header}>ข้อมูลการเงิน</div>
					<div className={style.explain}>เลือกมหาวิทยาลัยเพื่อดูการเงิน</div>
					<hr />
					
					<SelectSearch className='select-search-box-verifySheet' options={this.genUniOptions()} value={this.state.store.toString()} onChange={(value)=>this.setUni(value)} />
					<select className={style.monthyear} value={this.state.month} onChange={(event)=>{this.setState({month:event.currentTarget.value})}}>
						{this.genMonthOptions()}
					</select>
					<select className={style.monthyear} value={this.state.year} onChange={(event)=>{this.setState({year:event.currentTarget.value})}}>
						{this.genYearOptions()}
					</select>
					<button className={style.buttonPayment} onClick={(event)=>{this.fetchPayment()}}>
						เรียกดูข้อมูล
					</button>
					<hr />
					<div>รายได้ทั้งหมด : {this.state.total} บาท ยอดโอนให้ผู้ขาย 60%: {(this.state.total*0.6).toFixed(2)}</div>
					{this.renderTable()}
				</Container>
			</div>
		);
	}
	
	componentWillUnmount () {
		
	}
	
	genUniOptions = () => {
		if(this.state.data.stores.length===0)
			return [{value: '0', name: ''}];
		return this.state.data.stores.map(({name,code}) => (
				{value:code.toString(),name:name.join(' - ')}
			)
		);
	}
	
	setUni = async(value) => {
		this.setState({store:parseInt(value.value)});
	}
	
	genYearOptions = () => {
		var ops=[];
		var curr = new Date().getFullYear();
		for(var i=0;i<10;i++)
			ops.push(curr-i);
		return ops.map((y) => (
				<option label={'ปี '+y} value={y} key={y}>{'ปี '+y}</option>
			)
		);
	}
	
	genMonthOptions = () => {
		var ops=[];
		for(var i=1;i<=12;i++)
			ops.push(i);
		return ops.map((y) => (
				<option label={'เดือน '+y} value={y} key={y}>{'เดือน '+y}</option>
			)
		);
	}
	
	fetchPayment = async() => {
		var data = await Util.sendPost('/api/moresheetStaff/getSellPay',{store:this.state.store,month:parseInt(this.state.month),year:parseInt(this.state.year)});
		if(data.data.length==0) {
			alert('ไม่พบข้อมูล');
			return;
		}
		var realData=[];
		var tot=0;
		for(var i=0;i<data.data.length;i++) {
			tot+=data.data[i].total;
			realData.push({
				num:i+1,
				code:data.data[i].seller[0].codeName,
				trans:data.data[i].seller[0].bankInfo,
				total:data.data[i].total*0.6,
				exist:0,
				author:data.data[i].author,
				fb:data.data[i].seller[0].facebookID,
			});
		}
		await this.setState({payData:realData,total:tot});
		for(var i=0;i<data.data.length;i++)
			this.callForChange(i);
	}
	
	callForChange = async(num)=> {
		var data = await Util.checkReceipt(this.state.year,this.state.month,this.state.store,this.state.payData[num].fb);
		var x = this.state.payData;
		x[num].exist = data;
		this.setState({payData:x});
	}
	
	renderExist = (exist) => {
		if(exist==0)
			return (<div>กำลังโหลด...</div>);
		else if(exist==1)
			return (<div>ยังไม่มีรูป</div>);
		else
			return (<a href={exist}>ดูรูป</a>);
	}
	
	renderTable =() => {
		var t = this.state.payData.map((y) => {
			return (
				<tr key={y._id} className={style.row}>
					<td>{y.num}</td>
					<td>{y.code}</td>
					<td>{y.trans}</td>
					<td>{y.total.toFixed(2)}</td>
				</tr>
			)}
		);
		var res=(
			<table className={style.table}>
				<tbody>
					<tr className={style.row}>
						<th>ลำดับ</th>
						<th>นามปากกา</th>
						<th>ข่อมูลการโอนเงิน</th>
						<th>รายได้(60%)</th>
					</tr>
					{t}
				</tbody>
			</table>
		);
		return res;
	}
}

export default EditSheet;