import React from 'react';
import style from './Style.module.css';
import Util from '../../Util';
import {Hidden, Visible} from 'react-grid-system';
import { Link } from 'react-router-dom';


class Report extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showFull:false,
			action:props.report.action,
		};
	}

	render() {
		return (
			<React.Fragment>
				<div className={style.firstLine} onClick={(event)=>this.setState({showFull:!this.state.showFull})}>{this.props.report.created.substring(0,19)} (คลิกเพื่อดูรายละเอียด/จัดการ)</div>
				{this.state.showFull&&
				<React.Fragment>
					<div className={style.shead}>Sheet: <a href={'/staff/sheetedit?'+this.props.report.sheet} target='_blank' rel='noopenner noreferer'>คลิกเพื่อดู</a></div>
					<div className={style.shead}>หมวดหมู่:</div>
					<div className={style.smess}>{this.props.report.category}</div>
					<div className={style.shead}>ข้อความ:</div>
					<div className={style.smess}>{this.props.report.message}</div>
					<div className={style.shead}>Acion ที่ได้ทำไป (จะถูกบันทึกไว้รวมทั้งส่งให้ผู้ report):</div>
					<textarea className={style.sarea} value={this.state.action} onChange={(event)=>this.setState({action: event.currentTarget.value})} />
					<div onClick={(event)=>{this.props.takeAction(this.props.report._id,this.state.action);this.setState({showFull:false})}} className={style.sbutt}>จัดการเรียบร้อย</div>
				</React.Fragment>
				}
				<hr />
			</React.Fragment>
		);
	}
	
}

export default Report;