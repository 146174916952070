import React from 'react';
import { Link , Redirect } from 'react-router-dom';
import Util from '../../Util';
import style from './Style.module.css';
import {Container} from 'react-grid-system';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';


class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data:{
				user:{
					name:'',
					perm:[],
				},
			},
			dataList:[],
			quer:'',
			sellerData:{
				codeName:'-',
				name:'-',
				store:'-',
				faculty:'-',
				year:'-',
				peopleID:'-',
				bankInfo:'-',
				created:'-'
			},
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/moresheetStaff/authorMeta',{});
		if(!data.success)
			this.props.history.push('/staff');
		else {
			this.setState({data:data.data})
		}
	}

	render() {
		return (
			<div className={style.sheetPage}>
				<Container>
					<div className={style.header}>ฐานข้อมูลผู้ขาย</div>
					<div className={style.subheader}>Staff : {this.state.data.user.name}</div>
					<hr />
					<input type='text' value={this.state.quer} className={style.searchBar}  placeholder='ค้นหาตามนามปากกา' onChange={this.searcher}/>
					<div className={style.smallheader}>ผลลัพธ์การค้นหา (คลิกที่นามปากกาเพื่อดูรายละเอียดแต่ละคน)</div>
					{this.renderRes()}
					<hr />
					<div className={style.subheader}>ข้อมูล</div>
					<div className={style.label}>นามปากกา</div>
					<div className={style.value}>{this.state.sellerData.codeName}</div>
					<div className={style.label}>ชื่อ</div>
					<div className={style.value}>{this.state.sellerData.name}</div>
					<div className={style.label}>มหาวิทยาลัย</div>
					<div className={style.value}>{this.state.sellerData.store}</div>
					<div className={style.label}>คณะ</div>
					<div className={style.value}>{this.state.sellerData.faculty}</div>
					<div className={style.label}>ปี</div>
					<div className={style.value}>{this.state.sellerData.year}</div>
					<div className={style.label}>ข้อมูลบัตรประชาชน</div>
					<div className={style.value}>{this.state.sellerData.peopleID}</div>
					<div className={style.label}>ข้อมูลรับรายได้</div>
					<div className={style.value}>{this.state.sellerData.bankInfo}</div>
					<div className={style.label}>เป็นสมาชิกตั้งแต่</div>
					<div className={style.value}>{this.state.sellerData.created}</div>
				</Container>
			</div>
		);
	}
	
	renderRes=()=>{
		if(this.state.dataList.length==0)
			return (<div className={style.nothing}>ไม่พบ</div>);
		return this.state.dataList.map((seller) => (
				<div key={seller.facebookID} id={seller.facebookID} onClick={this.fetchSeller} className={style.sellerRes}>{seller.codeName}</div>
			)
		);
	}
	
	fetchSeller=async(event)=>{
		var data = await Util.sendPost('/api/moresheetStaff/authorInfo',{facebook:event.currentTarget.id});
		this.setState({sellerData:data.data});
	}
	
	searcher = async(event)=> {
		await this.setState({quer:event.currentTarget.value});
		var data = await Util.sendPost('/api/moresheetStaff/authorSearch',{query:this.state.quer});
		await this.setState({dataList:data.data});
	}
	
	componentWillUnmount () {
		
	}
}

export default withRouter(withAlert()(EditSheet));