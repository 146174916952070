import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import Util from '../../Util';
import style from './Style.module.css';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-grid-system';
import {
	GridContextProvider,
	GridDropZone,
	GridItem,
	swap,
	move
} from "react-grid-dnd";


class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data:{
				code:-1,
				name:["",""],
				color:["#000000","#000000","#000000","#000000"],
				faculty:[],
				facebookLink:"",
				useNewDrive:false,
				driveMap:[]
			},
			cover:false,
			updCover:false,
			updTemplate:false,
			template:false,
			wf:false,
			coverExample:false,
			driveDataMap:{},
			items: []
		};
	}

	async componentDidMount() {
		var unicode = window.location.search.split("?code=")[1]
		var data = await Util.sendGet('/api/moresheetStaff/getUni',{code:unicode});
		if (!data.success) {
			await alert(data.msg[0]);
			return this.props.history.push('/staff');
		}
		if(!data.data["useNewDrive"])
			data.data["useNewDrive"] = false
		
		if(!data.data["driveMap"])
			data.data["driveMap"] = []
		
		await this.populateDriveData(data.data)

		await this.setState({data:data.data});
		await this.setState({cover: process.env.REACT_APP_LOGO_URL + unicode + '.jpg?'+Date.now()});
		await this.setState({template: process.env.REACT_APP_TEMPLATE_URL + unicode + '.jpg?'+Date.now()});
		await this.coverExam();

	}

	render() {
		return (
			<React.Fragment>
				<div className={style.sheetPage}>
					<Container>
						<div className={style.container}>
							<div className={style.blankTop} />
							<div className={style.header}>Staff Store Management</div>
							<hr />
							<div className={style.label}>รหัส: {this.state.data.code}</div>
							<div className={style.label}>ชื่อไทย</div>
							<input type="text" className={style.textField} value={this.state.data.name[0]} onChange={(event)=>{this.changeNameData(0, event.currentTarget.value)}} />
							<div className={style.label}>ชื่ออังกฤษ/ตัวย่อ</div>
							<input type="text" className={style.textField} value={this.state.data.name[1]} onChange={(event)=>{this.changeNameData(1, event.currentTarget.value)}} />
							<div className={style.label}>Link Page Facebook</div>
							<input type="text" className={style.textField} value={this.state.data.facebookLink}  onChange={(event)=>{this.changeFacebookData(event.currentTarget.value)}} />
							<div className={style.label}>รหัสสีเงา Header</div>
							<input type="text" className={style.textField} value={this.state.data.color[0]} onChange={(event)=>{this.changeColorData(0, event.currentTarget.value)}} />
							<div className={style.label}>รหัสสีตัวหนังสือ</div>
							<input type="text" className={style.textField} value={this.state.data.color[1]} onChange={(event)=>{this.changeColorData(1, event.currentTarget.value)}} />
							<div className={style.label}>รหัสสีนามปากกา</div>
							<input type="text" className={style.textField} value={this.state.data.color[2]} onChange={(event)=>{this.changeColorData(2, event.currentTarget.value)}} />
							<div className={style.label}>รหัสสีราคา</div>
							<input type="text" className={style.textField} value={this.state.data.color[3]} onChange={(event)=>{this.changeColorData(3, event.currentTarget.value)}} />
							<div className={style.label}>List คณะ</div>
							{this.state.wf && <div className={style.labelRed}>WRONG JSON FORMAT!!</div>}
							<textarea type="text" className={style.textArea} value={Array.isArray(this.state.data.faculty)? JSON.stringify(this.state.data.faculty):this.state.data.faculty} onChange={(event)=>{this.changeFacultyData(event.currentTarget.value)}} />
						
							<div className={style.label}>Logo</div>
							{this.state.updCover ?
								<div className={style.uploadInfo}>กำลังอัพโหลด</div>
								:
								<React.Fragment>
									<input type='file' className={style['fileField']} onChange={(event) => { this.uploadCover(event.currentTarget.files[0]) }} />
									{
										this.state.cover ?
											<a href={this.state.cover} target='_blank' rel='noopener noreferrer'><img alt='' src={this.state.cover} className={style['coverImage']} /></a>
											:
											<div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
									}
								</React.Fragment>
							}
							<div className={style.label}>Template</div>
							{this.state.updTemplate ?
								<div className={style.uploadInfo}>กำลังอัพโหลด</div>
								:
								<React.Fragment>
									<input type='file' className={style['fileField']} onChange={(event) => { this.uploadTemplate(event.currentTarget.files[0]) }} />
									{
										this.state.cover ?
											<a href={this.state.template} target='_blank' rel='noopener noreferrer'><img alt='' src={this.state.template} className={style['coverImage2']} /></a>
											:
											<div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
									}
								</React.Fragment>
							}
							
							{this.newDriveDisplay()}
							
							<hr />

							<button className={style.saveButton} onClick={()=>this.saveUni()}>Save</button>

							<div className={style.label}>Cover Example</div>
							{
								this.state.coverExample ?
									<a href={this.state.coverExample} target='_blank' rel='noopener noreferrer'><img alt='' src={this.state.coverExample} className={style['coverImage2']} /></a>
									:
									<div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
							}
							<div style={{marginBottom:50}} />
						</div>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	populateDriveData = async(store) => {
		var driveData = await Util.sendGet('/api/public/drivelist', {});
		driveData = driveData.data[store.code];
		if(!driveData)
			driveData=[]
		let driveDataMap = {};
		let allDriveSet = new Set();
		let definedDriveSet = new Set();

		// All existing drive
		for(let i=0;i<driveData.length;i++) {
			let drive = driveData[i];
			allDriveSet.add(drive.code);
			driveDataMap[drive.code] = {
				name: drive.name,
				subject:drive.subject
			}
		}

		let items = []
		// Defined in drive, delete ones that are non-existent
		for(let i=0;i<store.driveMap.length;i++) {
			let group = store.driveMap[i];
			items.push({name: group.name, items:[]});
			for(let i2=0;i2<group.items.length;i2++) {
				if(allDriveSet.has(group.items[i2])) {
					items[i].items.push(group.items[i2]);
					definedDriveSet.add(group.items[i2]);
				}
			}
		}

		let lastgroup = {
			name:"ยังไม่ได้จัดหมวดหมู่",
			items: []
		}
		for (let nowDrive of allDriveSet) 
			if(!definedDriveSet.has(nowDrive))
				lastgroup.items.push(nowDrive);
		
		items.push(lastgroup);
		await this.setState({driveDataMap, items});
	}

	onDndChange = (sourceId, sourceIndex, targetIndex, targetId) => {
		sourceId = parseInt(sourceId);
		targetId = parseInt(targetId);
		if (!isNaN(targetId)) {
		  const result = move(
			this.state.items[sourceId].items,
			this.state.items[targetId].items,
			sourceIndex,
			targetIndex
		  );
		  let newItems = this.state.items;
		  newItems[sourceId].items = result[0];
		  newItems[targetId].items = result[1];
		  return this.setState({items:newItems});
		}
	
		const result = swap(this.state.items[sourceId].items, sourceIndex, targetIndex);
		let newItems = this.state.items;
		newItems[sourceId].items = result;
		return this.setState({items:newItems});
	  }

	changeGroupName = (index, value) => {
		let items = this.state.items;
		items[index].name = value;
		this.setState({items});
	}

	swapUp = (index) => {
		let items = this.state.items;
		let tmp = items[index-1];
		items[index-1] =items[index];
		items[index] = tmp; 
		this.setState({items});
	}

	swapDown = (index) => {
		let items = this.state.items;
		let tmp = items[index+1];
		items[index+1] =items[index];
		items[index] = tmp; 
		this.setState({items});
	}

	swapDelete = (index) => {
		let items = this.state.items;
		let tmp = items[index];
		items.splice(index,1);
		items[items.length-1].items = items[items.length-1].items.concat(tmp.items);
		this.setState({items});
	}

	swapAdd = () => {
		let items = this.state.items;
		items.splice(items.length-1,0, {name:"New Group", items:[]});
		this.setState({items});
	}

	newDriveDisplay = () => {
		return (
			<React.Fragment>
				<div className={style.label}>New Drive Display(อย่าลืมกด Save)</div>
				{ this.state.data.useNewDrive?
					<button className={style.toggleDriveOn} onClick={()=>this.toggleNewDrive()}>สถานะ: เปิดการใช้งาน</button>:
					<button className={style.toggleDriveOff} onClick={()=>this.toggleNewDrive()}>สถานะ: ปิดการใช้งาน</button>
				}
				<GridContextProvider onChange={this.onDndChange}>
					<div className="container">
						{
							this.state.items.map((group, index)=>(
								<React.Fragment>
									{
										index===this.state.items.length-1?
										<React.Fragment>
											<div className={style.rowContainer}>
												<div className={style.weirdinput}>{group.name}</div>
												<div onClick={(event)=>this.swapAdd()} className={style.addSwap}>+</div>
											</div>
										</React.Fragment>
										:
										<React.Fragment>
											<div className={style.rowContainer}>
												<input className={style.weirdinput} type="text" value={group.name} onChange={(event) => this.changeGroupName(index, event.currentTarget.value)} />
												{index!==0 && <div onClick={(event)=>this.swapUp(index)} className={style.posSwap}>⇑</div>}
												{index!==this.state.items.length-2 && <div onClick={(event)=>this.swapDown(index)} className={style.posSwap}>⇓</div>}
												<div onClick={(event)=>this.swapDelete(index)} className={style.deleteSwap}>X</div>
											</div>
										</React.Fragment>
									}
									
									<GridDropZone
										className={style.dropzone}
										id={index.toString()}
										boxesPerRow={4}
										rowHeight={100}
										style={{
											height: 100 * (Math.ceil(group.items.length / 4)+1)
										}}
									>
										{group.items.map(item => (
											<GridItem key={item}>
											<div className={style["grid-item"]}>
												<div className={style["grid-item-content"]} style={{
													backgroundImage:`url(${process.env.REACT_APP_LOGO_URL + item.toString() +'.jpg'})`,
													backgroundSize: "contain"
												}} />
												<div className={style.underName}>{this.state.driveDataMap[item].name}</div>
											</div>
											</GridItem>
										))}
									</GridDropZone>
								</React.Fragment>
							))
						}
						
					</div>
					</GridContextProvider>
			</React.Fragment>
		)
	}

	toggleNewDrive = () =>{
		var x = this.state.data;
		x.useNewDrive = !x.useNewDrive
		this.setState({data:x});
	}

	changeNameData = (index, data) =>{
		var x = this.state.data;
		x.name[index] = data;
		this.setState({data:x});
	}

	changeColorData = (index, data) =>{
		var x = this.state.data;
		x.color[index] = data;
		this.setState({data:x});
	}

	changeFacebookData = (data) =>{
		var x = this.state.data;
		x.facebookLink = data;
		this.setState({data:x});
	}

	changeFacultyData = (data) =>{
		var x = this.state.data;
		try {
			x.faculty = JSON.parse(data);
			this.setState({wf:false});
		}
		catch(err) {
			x.faculty = data;
			this.setState({wf:true});
		}
		this.setState({data:x});
	}

	uploadCover = async (file) => {
		await this.setState({ updCover: true });
		var data = await Util.sendPost('/api/moresheetStaff/uploadUniLogoUrl', { id: this.state.data.code });

		var formData = new FormData();
		formData.append('acl', 'public-read');
		var keys = Object.keys(data.data.fields);
		for (var i = 0; i < keys.length; i++)
			formData.append(keys[i], data.data.fields[keys[i]]);
		formData.append('file', file);
		await Util.sendFormData(data.data.url, formData);

		await this.setState({ updCover: false });
		await this.setState({cover: process.env.REACT_APP_LOGO_URL + this.state.data.code + '.jpg?'+Date.now()})
	}

	uploadTemplate = async (file) => {
		await this.setState({ updTemplate: true });
		var data = await Util.sendPost('/api/moresheetStaff/uploadUniTemplateUrl', { id: this.state.data.code });

		var formData = new FormData();
		formData.append('acl', 'public-read');
		var keys = Object.keys(data.data.fields);
		for (var i = 0; i < keys.length; i++)
			formData.append(keys[i], data.data.fields[keys[i]]);
		formData.append('file', file);
		await Util.sendFormData(data.data.url, formData);

		await this.setState({ updTemplate: false });
		await this.setState({template: process.env.REACT_APP_TEMPLATE_URL + this.state.data.code + '.jpg?'+Date.now()});
		await Util.sendPost('/api/moresheetStaff/pullTemplate', { id: this.state.data.code });
		await this.coverExam();
	}

	saveUni = async() => {
		var data = await Util.sendPost('/api/moresheetStaff/saveUni', { 
			code: this.state.data.code,
			name: this.state.data.name,
			faculty: this.state.data.faculty,
			color: this.state.data.color,
			facebookLink: this.state.data.facebookLink,
			useNewDrive: this.state.data.useNewDrive,
			driveMap: this.state.items.slice(0,this.state.items.length-1)
		});
		if(data.success) {
			alert("save แล้ว");
			await this.coverExam();
		}	
		else
			alert("something broke ask poom");
	}

	coverExam = async()=>{
		var b = await Util.sendGetBlob('/api/moresheetStaff/uniCoverExample',{code:this.state.data.code});
		this.setState({coverExample: URL.createObjectURL(b)})
	}

}

export default withRouter(withAlert()(EditSheet));