import React, { PureComponent, createRef } from "react";
import Util from "../../Util";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import SellSheet from "../../Components/ShopSheet";
import SheetGroup from "../../Components/SheetGroup";
import style from "./Style.module.css";
import { Link } from "react-router-dom";
import { Visible, Hidden, Container } from "react-grid-system";
import "./style.css";
import SelectSearch from "react-select-search";
import InfiniteScroll from "react-infinite-scroller";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { Mutex, Semaphore, withTimeout } from "async-mutex";

class Sell extends React.Component {
  ref = createRef();

  scrolltoTop = () => {
    const scroll = this.ref.current.getParentElement(
      this.ref.current.scrollComponent
    );
    scroll.scrollTop = 0;
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirectFail: false,
      redirectSheet: false,
      sheetID: "",
      data: {
        user: {
          infoEnter: true,
        },
        store: 0,
        storeList: [],
      },
      sheets: {
        page: 0,
        loading: false,
        hasmore: true,
        disp: [],
        timeStamp: 0,
      },
      filter: {
        sort: "date",
        search: "",
        sem: "any",
        year: "0",
        store: -1,
        cat: -1,
      },
      recGroup: [],
      driveData: {},
      storeFacebook: {},
      storeData: {},
      driveDataMap: {},
      pagemutex: 0,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (
      window.location.search &&
      window.location.search.substring(1, 4) === "ref"
    )
      localStorage.setItem("buyRef", window.location.search.substring(1));
    this.mutex = new Mutex();
    var data = await Util.sendPost("/api/public/getShop", {});
    if (data.success) {
      await this.setState({ data: data.data });
      var fil = this.state.filter;
      fil.store = data.data.store;
      if (
        this.props.location.search.trim().length != 0 &&
        !isNaN(this.props.location.search.substring(1))
      )
        fil.store = parseInt(this.props.location.search.substring(1));
      await this.setState({ filter: fil });
      this.reloadSheets();
    } else await this.setState({ redirectFail: true });
    var driveData = await Util.sendGet("/api/public/drivelist", {});
    if (driveData.success) {
      await this.setState({ driveData: driveData.data });
    } else await this.setState({ redirectFail: true });
    await this.reloadStoreData();
    var storeFacebook = await Util.sendGet("/api/public/storeFacebook", {});
    if (storeFacebook.success) {
      await this.setState({ storeFacebook: storeFacebook.data });
    } else await this.setState({ redirectFail: true });
  }

  render() {
    return (
      <React.Fragment>
        <div className={style.shopPage}>
          <Nav current="shop" user={this.state.data.user} />
          <Container>
            <div className={style.blanker} />
            <div className={style.bigHeader}>ซื้อชีท</div>
            <div className={style.sellLinkLine}>
              <div className={style.sellLinkText}>ต้องการขายชีท?</div>
              <Link to="/seller">
                <div className={style.sellLinkLink}>สมัครเป็นนักทำชีท</div>
              </Link>
            </div>
            <div className={style.sellLinkLine}>
              <Link to="/mysheet">
                <div className={style.sellLinkLink}>อ่านชีทสรุปที่ซื้อไว้</div>
              </Link>
            </div>
            <Hidden xs sm md>
              <div className={style.filterBar}>
                <input
                  type="text"
                  placeholder="ค้นหาชีท..."
                  className={style.search}
                  value={this.state.filter.search}
                  onChange={(event) =>
                    this.setFilter("search", event.currentTarget.value)
                  }
                />
                <SelectSearch
                  className="select-search-box-shop"
                  options={this.genUniOptions()}
                  value={this.state.filter.store.toString()}
                  onChange={(value) => {
                    this.setFilter("store", parseInt(value.value));
                  }}
                />
                <select
                  className={style.semSelector}
                  value={this.state.filter.sem}
                  onChange={(event) =>
                    this.setFilter("sem", event.currentTarget.value)
                  }
                >
                  {this.genTermOptions()}
                </select>
                <select
                  className={style.semSelector}
                  value={this.state.filter.year}
                  onChange={(event) =>
                    this.setFilter("year", event.currentTarget.value)
                  }
                >
                  {this.genYearOptions()}
                </select>
                <select
                  className={style.semSelector}
                  value={this.state.filter.cat}
                  onChange={(event) =>
                    this.setFilter("cat", event.currentTarget.value)
                  }
                >
                  {this.genCatOptions()}
                </select>
                <select
                  className={style.semSelector}
                  value={this.state.filter.sort}
                  onChange={(event) =>
                    this.setFilter("sort", event.currentTarget.value)
                  }
                >
                  {this.genSortOptions()}
                </select>
              </div>
              {this.state.storeData.useNewDrive ? (
                this.genNewDrive(true)
              ) : (
                <div className={style.driveContainer}>
                  {this.genDriveLink(style.uniPicBig)}
                  <img
                    src={process.env.REACT_APP_LOGO_URL + "LSPACE.jpg"}
                    className={style.uniPicBig}
                    alt=""
                    onClick={(event) => this.setFilter("cat", 3)}
                  />
                  {this.genShopFacebook(style.uniPicBig)}
                </div>
              )}
            </Hidden>
            <Visible xs sm md>
              <div className={style.barMobile + " " + style.searcher}>
                <input
                  type="text"
                  placeholder="ค้นหาชีท..."
                  className={style.search + " " + style.mobile}
                  value={this.state.filter.search}
                  onChange={(event) =>
                    this.setFilter("search", event.currentTarget.value)
                  }
                />
              </div>
              <div className={style.barMobile + " " + style.store}>
                <SelectSearch
                  className="select-search-box-shop"
                  options={this.genUniOptions()}
                  value={this.state.filter.store.toString()}
                  onChange={(value) => {
                    this.setFilter("store", parseInt(value.value));
                  }}
                />
              </div>
              <div className={style.barMobile + " " + style.filter}>
                <select
                  className={style.semSelector + " " + style.mobile}
                  value={this.state.filter.sem}
                  onChange={(event) =>
                    this.setFilter("sem", event.currentTarget.value)
                  }
                >
                  {this.genTermOptions()}
                </select>
                <select
                  className={
                    style.semSelector + " " + style.mobile + " " + style.last
                  }
                  value={this.state.filter.year}
                  onChange={(event) =>
                    this.setFilter("year", event.currentTarget.value)
                  }
                >
                  {this.genYearOptions()}
                </select>
              </div>
              <div className={style.barMobile + " " + style.filter}>
                <select
                  className={style.semSelector + " " + style.mobile}
                  value={this.state.filter.cat}
                  onChange={(event) =>
                    this.setFilter("cat", event.currentTarget.value)
                  }
                >
                  {this.genCatOptions()}
                </select>
                <select
                  className={
                    style.semSelector + " " + style.mobile + " " + style.last
                  }
                  value={this.state.filter.sort}
                  onChange={(event) =>
                    this.setFilter("sort", event.currentTarget.value)
                  }
                >
                  {this.genSortOptions()}
                </select>
              </div>
              {this.state.storeData.useNewDrive ? (
                this.genNewDrive(false)
              ) : (
                <div className={style.driveContainer}>
                  {this.genDriveLink(style.uniPic)}
                  <img
                    src={process.env.REACT_APP_LOGO_URL + "LSPACE.jpg"}
                    className={style.uniPic}
                    alt=""
                    onClick={(event) => this.setFilter("cat", 3)}
                  />
                  {this.genShopFacebook(style.uniPic)}
                </div>
              )}
            </Visible>
            {this.state.recGroup.length !== 0 ? (
              <React.Fragment>
                <div className={style.sheetCatLabel}>แพคเกจชีทแนะนำ</div>
                <div className={style.sheetWrapper}>{this.renderGroup()}</div>
                <div className={style.hrLineTop} />
                <div className={style.sheetCatLabel}>ผลการค้นหาทั่วไป</div>
              </React.Fragment>
            ) : (
              <div className={style.spacebot} />
            )}
            <div className={style.fillbot}>
              <InfiniteScroll
                ref={this.ref}
                key="scroller"
                pageStart={-1}
                loadMore={this.loadItems}
                hasMore={this.state.sheets.hasmore}
                loader={<div key="loader" className={style.loader} />}
              >
                <div className={style.sheetWrapper}>{this.renderStuff()}</div>
              </InfiniteScroll>
            </div>
          </Container>
          <div className={style.blanker} />
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  genNewDrive = (big) => {
    let uniStyle = big ? style.uniPicBig : style.uniPic;
    return (
      <React.Fragment>
        <div className={style.driveContainer}>
          <img
            src={process.env.REACT_APP_LOGO_URL + "LSPACE.jpg"}
            className={uniStyle}
            alt=""
            onClick={(event) => this.setFilter("cat", 3)}
          />
          {this.genShopFacebook(uniStyle)}
        </div>
        {this.state.storeData.driveMap.map((group, index) => (
          <div id={group.name} className={style.oneBlack}>
            <div className={style.oneBlackName}>
              <div>{group.name}</div>
              <div
                className={style.expand}
                onClick={(event) => this.toggleExpand(index)}
              >
                {group.expand ? "ซ่อน" : "ดูทั้งหมด"}
              </div>
            </div>
            <div
              className={style.newDriveContain}
              style={
                group.expand
                  ? {}
                  : { flexWrap: "nowrap", height: "250px", overflow: "hidden" }
              }
            >
              {group.items.map((drive) => (
                <Link
                  key={"drive" + drive.toString()}
                  to={"/secretshop?" + drive.toString()}
                  target="_blank"
                >
                  <div className={style.oneDrive}>
                    <img
                      src={
                        process.env.REACT_APP_LOGO_URL +
                        drive.toString() +
                        ".jpg"
                      }
                      className={uniStyle}
                      alt=""
                    />
                    {big && (
                      <React.Fragment>
                        <div className={style.driveName}>
                          {this.state.driveDataMap[drive] &&
                            this.state.driveDataMap[drive].name}
                        </div>
                        <div className={style.driveSubj}>
                          Subject:{" "}
                          {this.state.driveDataMap[drive] &&
                            this.state.driveDataMap[drive].subject}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  };

  toggleExpand = (index) => {
    let storeData = this.state.storeData;
    storeData.driveMap[index].expand = !storeData.driveMap[index].expand;
    this.setState({ storeData });
  };

  reloadStoreData = async () => {
    let storeSelect;
    for (let i = 0; i < this.state.data.storeList.length; i++) {
      let store = this.state.data.storeList[i];
      if (store.code === this.state.filter.store) {
        // add expand
        for (let i2 = 0; i2 < store.driveMap.length; i2++) {
          store.driveMap[i2].expand = false;
        }
        storeSelect = store;
        break;
      }
    }

    let definedDriveSet = new Set();
    for (let i = 0; i < storeSelect.driveMap.length; i++) {
      let group = storeSelect.driveMap[i];
      for (let i2 = 0; i2 < group.items.length; i2++) {
        definedDriveSet.add(group.items[i2]);
      }
    }

    let driveDataMap = {};
    let driveData = this.state.driveData[storeSelect.code];
    if (!driveData) driveData = [];
    let newItem = { name: "อื่นๆ", items: [] };

    for (let i = 0; i < driveData.length; i++) {
      let drive = driveData[i];
      if (!definedDriveSet.has(drive.code)) newItem.items.push(drive.code);
      driveDataMap[drive.code] = {
        name: drive.name,
        subject: drive.subject,
      };
    }
    if (newItem.items.length > 0) storeSelect.driveMap.push(newItem);
    await this.setState({ driveDataMap, storeData: storeSelect });
  };

  genDriveLink = (className) => {
    var driveData = this.state.driveData;
    var currStore = this.state.filter.store.toString();
    if (!driveData.hasOwnProperty(currStore)) return <React.Fragment />;
    if (!driveData[currStore]) return <React.Fragment />;
    return driveData[currStore].map((drive) => {
      return (
        <Link
          target="_blank"
          key={"drive" + drive.code.toString()}
          to={"/secretshop?" + drive.code.toString()}
        >
          <img
            src={
              process.env.REACT_APP_LOGO_URL + drive.code.toString() + ".jpg"
            }
            className={className}
            alt=""
          />
        </Link>
      );
    });
  };

  genShopFacebook = (className) => {
    var storeFacebook = this.state.storeFacebook;
    var currStore = this.state.filter.store.toString();
    if (!storeFacebook.hasOwnProperty(currStore)) return <React.Fragment />;
    if (!storeFacebook[currStore]) return <React.Fragment />;
    return (
      <a
        target="_blank"
        rel="noopenner noreferer"
        href={storeFacebook[currStore]}
      >
        <img
          src={process.env.REACT_APP_LOGO_URL + "facebooklogo.png"}
          className={className}
          alt=""
        />
      </a>
    );
  };

  scrolltoTop = () => {
    const scroll = this.ref.current.getParentElement(
      this.ref.current.scrollComponent
    );
    scroll.scrollTop = 0;
  };

  genTermOptions = () => {
    const ops = [
      { label: "any", value: "any" },
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
    ];
    return ops.map(({ label, value }) => (
      <option
        label={label === "any" ? "ทุกเทอม" : label}
        value={value}
        key={value}
      >
        {label === "any" ? "ทุกเทอม" : label}
      </option>
    ));
  };

  genYearOptions = () => {
    var ops = [0];
    var curr = new Date().getFullYear();
    for (var i = 0; i < 10; i++) ops.push(curr - i);
    return ops.map((y) => (
      <option label={y === 0 ? "ทุกปี" : y} value={y} key={y}>
        {y === 0 ? "ทุกปี" : y}
      </option>
    ));
  };

  genSortOptions = () => {
    const ops = [
      { label: "ราคาต่ำขึ้นก่อน", value: "price" },
      { label: "คนซื้อมากขึ้นก่อน", value: "sold" },
      { label: "ล่าสุดขึ้นก่อน", value: "date" },
    ];
    return ops.map(({ label, value }) => (
      <option label={label} value={value} key={value}>
        {label}
      </option>
    ));
  };

  setFilter = async (field, val) => {
    var x = this.state.filter;
    x[field] = val;
    await this.setState({ filter: x });
    this.reloadSheets();
    this.reloadRecGroup();
    this.reloadStoreData();
  };

  reset() {
    if (this.ref && this.ref.current) {
      this.ref.current.pageLoaded = this.ref.current.props.pageStart;

      this.scrolltoTop();
    }
  }

  reloadSheets = async () => {
    var time = new Date().getTime();
    this.reset();
    await this.setState({
      sheets: {
        page: 0,
        loading: true,
        hasmore: true,
        disp: [],
        timeStamp: time,
      },
      pagemutex: 0,
    });
  };

  genUniOptions = () => {
    if (this.state.data.storeList.length === 0)
      return [{ value: "0", name: "" }];
    return this.state.data.storeList.map(({ name, short, code }) => ({
      value: code.toString(),
      name: name[0],
    }));
  };

  genCatOptions = () => {
    const ops = [
      { label: "ไฟนอล", value: 2 },
      { label: "มิดเทอม", value: 1 },
      { label: "อื่นๆ", value: 0 },
      { label: "Learning Space", value: 3 },
      { label: "ทุกประเภท", value: -1 },
    ];
    return ops.map(({ label, value }) => (
      <option label={label} value={value} key={value}>
        {label}
      </option>
    ));
  };

  renderStuff = () => {
    if (this.state.sheets.disp.length === 0 && !this.state.sheets.hasmore)
      return <div className={style.nothing}>ไม่พบรายการ</div>;
    return this.state.sheets.disp.map((sheet) => {
      return (
        <SellSheet
          sheet={sheet}
          key={"s" + sheet._id}
          store={{}}
          addCart={this.addCart}
        ></SellSheet>
      );
    });
  };

  renderGroup = () => {
    return this.state.recGroup.map((group) => {
      return (
        <SheetGroup
          key={"g" + group._id}
          group={group}
          addCart={this.addGroupCart}
        ></SheetGroup>
      );
    });
  };

  addGroupCart = async (group) => {
    var comp = true;
    for (var i = 0; i < group.sheets.length; i++) {
      var id = group.sheets[i];
      var data = await Util.sendPost("/api/public/ownSheet", { id });
      if (data.success) {
        var res = await Util.addCartGroup(id, group.name);
      } else comp = false;
    }
    if (!comp)
      this.props.alert.show(
        "คุณเป็นเจ้าของบางชีทอยู่แล้ว ชีทเหล่านั่นจะไม่ถูกเพิ่มลงตะกร้า",
        { type: "info" }
      );
    else
      this.props.alert.show("เพิ่มชีททั้งหมดลงตะกร้าสำเร็จ", {
        type: "success",
      });
  };

  addCart = async (id) => {
    var data = await Util.sendPost("/api/public/ownSheet", { id });
    if (data.success) {
      var res = await Util.addCart(id);
      if (res)
        this.props.alert.show("เพิ่มชีทลงตะกร้าสำเร็จ", { type: "success" });
      else this.props.alert.show("มีชีทนี้ในตะกร้าอยู่แล้ว", { type: "error" });
    } else
      this.props.alert.show("คุณเป็นเจ้าของชีทนี้อยู่แล้ว", { type: "error" });
  };

  loadItems = async (page) => {
    try {
      var sorter = {
        price: "price",
        score: "-score",
        sold: "-bought",
        date: "-created",
      };
      var x = this.state.sheets;
      x.page = page;
      var data = await Util.sendPost("/api/public/searchSheet", {
        search: this.state.filter.search.toLowerCase(),
        filter: {
          store: this.state.filter.store,
          semester:
            this.state.filter.sem === "any" ? undefined : this.state.filter.sem,
          year:
            this.state.filter.year === "0" ? undefined : this.state.filter.year,
          category:
            this.state.filter.cat.toString() === "-1"
              ? undefined
              : this.state.filter.cat,
        },
        sort: sorter[this.state.filter.sort],
        page: page,
        pageLimit: 20,
        timeStamp: x.timeStamp,
      });
      while (this.state.pagemutex < page) await Util.sleep(10);
      x = this.state.sheets;
      if (data.success && x.timeStamp === data.timeStamp) {
        if (data.data.length === 0) x.hasmore = false;
        var has = x.disp.map((h) => h._id);
        for (var i = 0; i < data.data.length; i++)
          if (!has.includes(data.data[i]._id)) x.disp.push(data.data[i]);
        this.setState({
          sheets: x,
          pagemutex: page + 1,
        });
      }
    } finally {
    }
  };

  reloadRecGroup = async () => {
    var timeStamp = new Date().getTime();
    var data = await Util.sendPost("/api/public/recGroup", {
      search: this.state.filter.search.toLowerCase(),
      store: this.state.filter.store,
      timeStamp,
    });
    var x = this.state.sheets;
    if (data.success && timeStamp === data.timeStamp)
      this.setState({ recGroup: data.data });
  };
}

export default withRouter(withAlert()(Sell));
