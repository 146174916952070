import React from 'react';
import {Container,Visible,Hidden} from 'react-grid-system';
import { Link , Redirect } from 'react-router-dom';
import style from './Style.module.css';
import logo from '../../asset/logo.png';
import logonav from '../../asset/logonav.png';
import cartPic from '../../asset/cart.svg';
import bellPic from '../../asset/bell.svg';
import popup from '../../asset/kiki_popup.png';
import Util from '../../Util';

const menus = [
	{match:'home',to:'/',label:'หน้าแรก'},
	{match:'shop',to:'/shop',label:'ซื้อชีท'},
	{match:'seller',to:'/seller',label:'ขายชีท'},
	{match:'learningspace',to:'/learningspace',label:'Learning Space'},
	{match:'about',to:'/faq',label:'ช่วยเหลือ'},
];

class Nav extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			open:false,
			sideopen:false,
			unreadMsg:0,
			dialog:false,
			timeOut:0,
			popupEmail:''
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/user/getNumUnreadMessage');
		if(data.success)
			this.setState({unreadMsg:data.data.count});
		if(!localStorage.getItem('auth'))
			this.setState({timeOut:setTimeout(this.showdialog, 10000)});
	}

	componentWillUnmount(){
		clearTimeout(this.state.timeOut)
	}

	showdialog = () => {
		if(sessionStorage.getItem('dialog'))
			return;
		sessionStorage.setItem('dialog','true');
		this.setState({dialog:true});
	}

	render() {
		const lang = Util.getLang();
		const cart = Util.getCart();
		return (
			<React.Fragment>
				{this.props.user&&(!this.props.user.infoEnter) && <Redirect to='/infoEnter' />}
				{this.state.unreadMsg>0&&!this.props.ale && <Link to='/message'><div className={style.unread}>คุณมี {this.state.unreadMsg} การแจ้งเตือนใหม่ คลิกเพื่ออ่าน</div></Link>}
				{this.state.dialog && <div className={style.popup}>
					<div className={style.popupimgcon}>
						<img src={popup} className={style.popupimg} />
						<input type='text' className={style.popupText} placeholder='example@example.com' value={this.state.popupEmail} onChange={(event)=>this.setState({popupEmail:event.currentTarget.value})} />
						<div className={style.popupClose} onClick={(event)=>this.setState({dialog:false})}>X</div>
						<div className={style.popupButt} onClick={this.sendEmail}/>
					</div>
				</div>}
				<Hidden xs sm md>
					<div className={style.navBlock} />
					<Container>
						<div className={style.navContainer}>
							<div className={style.logoNav}><img src={logonav} /></div>
							<div className={style.allright}>
								<div className={style.menuCon}>
									{menus.map((menu) => {
											var curr = [style.listLeft];
											if(this.props.current===menu.match)
												curr.push(style.active);
											return (<Link to={menu.to} key={menu.match}><div className ={curr.join(' ')} >{menu.label}</div></Link>);
										}
									)}
								</div>
								<div className={style.rightCon}>
									{
										this.props.user && 
										<React.Fragment>
											<Link to='/message'><img alt='' src ={bellPic} className={style.bell} /></Link>
											{cart.length>0&&<div className={style.cartSize}>{cart.length}</div>}
										</React.Fragment>
									}
									<Link to='/cart'>
										<img alt='' src ={cartPic} className={style.cart} />
										{this.state.unreadMsg>0&&<div className={style.cartSize}>{this.state.unreadMsg}</div>}
									</Link>
									{
										this.props.user?
											(<React.Fragment>
												<div className={style.userPanel} onClick={()=>{this.setState({sideopen:!this.state.sideopen})}}>
													<img alt='' src ={process.env.REACT_APP_USER_PIC+this.props.user._id+'.jpg'} className={style.userPic} />
												</div>
												{this.state.sideopen?
													<div className={style.sidePanel}>
														<Link to='/mysheet'><div className={style.linkage}>ชีทของฉัน</div></Link>
														<Link to='/favorite'><div className={style.linkage}>รายการโปรด</div></Link>
														<Link to='/message'><div className={style.linkage}>ข้อความแจ้งเตือน</div></Link>
														<div className={style.logout} onClick={this.logout}>ออกจากระบบ</div>
													</div>
													:
													null
												}
											</React.Fragment>)
										:
											(<Link to='/login'><div className={style.loginButton}>เข้าสู่ระบบ</div></Link>)
									}
								</div>
							</div>
						</div>
					</Container>
				</Hidden>
				
				<Visible xs sm md>
					{!this.state.open&&this.navMobileStuff(cart,true)}
					{this.state.open&&<div className = {this.state.open?[style.navPanelMobile,style.open].join(' '):style.navPanelMobile}>
						{this.navMobileStuff(cart,false)}
						<div className={style.navPanelContent}>
							<div className = {style.mobileBreak} />
							{menus.map((menu) => {
									var curr = [style.listMobile,style['listMobileText'+lang.toString()]];
									if(this.props.current===menu.match)
										curr.push(style.active);
									return (<Link to={menu.to} key={menu.match}><div className ={curr.join(' ')}>{menu.label}</div></Link>);
								}
							)}
							<div className = {style.mobileBreak} />
							{
								this.props.user?
									(<React.Fragment>
										<img alt='' src ={process.env.REACT_APP_USER_PIC+this.props.user._id+'.jpg'} className={style.userPicMobile} />
										<div className={style.userTextMobile}>{this.props.user.name}</div>
										<Link to={'/mysheet'}><div className ={[style['listMobileText'+lang.toString()],style.listMobile].join(' ')}>ชีทของฉัน</div></Link>
										<Link to={'/favorite'}><div className ={[style['listMobileText'+lang.toString()],style.listMobile].join(' ')}>รายการโปรด</div></Link>
										<Link to={'/message'}><div className ={[style['listMobileText'+lang.toString()],style.listMobile].join(' ')}>ข้อความแจ้งเตือน</div></Link>
										<div className={style.logoutMobile} onClick={this.logout}>ออกจากระบบ</div>
									</React.Fragment>)
								:
									(<Link to='/login'><div className={style.loginButtonMobile}>เข้าสู่ระบบ</div></Link>)
							}
						</div>
					</div>}
				</Visible>
			</React.Fragment>
		);
	}
	
	sendEmail = async(event) =>{
		this.setState({dialog:false});
		await Util.sendPost('/api/public/logStranger',{email: this.state.popupEmail});
	}

	navMobileStuff=(cart,outer)=>{
		
			return (<div className = {style.navContainerMobile+(this.state.open&&outer?(' '+style.outer):'')}>
						<div className={style.hamContainer} 
							onClick={()=>{
								this.setState({open:!this.state.open});
							}}
						>
							<span className={this.state.open?[style.line1,style.open].join(' '):style.line1} />
							<span className={this.state.open?[style.line2,style.open].join(' '):style.line2} />
							<span className={this.state.open?[style.line3,style.open].join(' '):style.line3} />
						</div>
						<img alt='' src ={logo} className={style.logoMid} />
						<div className={style.rightMobile}>
							<Link to='/cart'>
								<img alt='' src ={cartPic} className={style.cartMobile} />
								{cart.length>0&&<div className={style.cartSizeMobile}>{cart.length}</div>}
							</Link>
							{this.props.user&&<img alt='' src ={process.env.REACT_APP_USER_PIC+this.props.user._id+'.jpg'} className={style.userPicNavMobile} />}
						</div>
	</div>);}
	
	logout=()=>{
		localStorage.setItem('auth','');
		window.location.reload();
	}
}

export default Nav;