import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import style from './Style.module.css';
import { Link, Redirect } from 'react-router-dom';
import { Container } from 'react-grid-system';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import Footer from '../../Components/Footer';
import QRImage from '../../asset/qr.png';
import QRTop2 from '../../asset/qrTop2.jpg';
import announce from '../../asset/wallet.jpg';
var QRCode = require('qrcode.react');


const month = [['มกราคม', 'ม.ค.'],
['กุมภาพันธ์', 'ก.พ.'],
['มีนาคม', 'มี.ค.'],
['เมษายน', 'เม.ษ.'],
['พฤษภาคม', 'พ.ค.'],
['มิถุนายน', 'มิ.ย.'],
['กรกฎาคม', 'ก.ค.'],
['สิงหาคม', 'ส.ค.'],
['กันยายน', 'ก.ย.'],
['ตุลาคม', 'ต.ค.'],
['พฤศจิกายน', 'พ.ย.'],
['ธันวาคม', 'ธ.ค.'],
]

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: {
				user: {
					infoEnter: true,
				},
				cart: [],
				order: {
					price: 0,
					promoCode: '',
					qrData: '',
				},
				hex: ''
			},
			intervalID: 0,
			slip: ''
		};
	}

	async componentDidMount() {
		await this.verifyOrder();
		var intervalID = setInterval(this.checkPayment, 5000);
		await this.setState({ intervalID });
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalID);
	}

	render() {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				<div className={style.shopPage}>
					<Nav current='' user={this.state.data.user} />
					<div className={style.topBlank} />
					<Container>
						<div className={style.bigheader}>รายการสั่งซื้อ</div>
						<div className={style.payHeader}>ขั้นตอนการชำระเงิน</div>
						<div className={style.payInfo}>แสกน QR Code ด้านล่าง ด้วย Application ของธนาคารใดก็ได้ <b className={style.warning}>ยกเว้น Truemoney Wallet</b> เพื่อทำการจ่ายเงิน เพื่อพบรายการชำระเงิน ระบบจะนำท่านไปยังหน้า ชีทของฉัน โดยอัตโนมัติ</div>
						<img src={announce} className={style.walletAnnounce} />
						{this.state.data.order.qrData !== '' &&
							<React.Fragment>
								<img className={style.QRTop2} src={QRTop2} alt='' />
								<QRCode value={this.state.data.order.qrData} level='H' size={200} style={{ marginTop: '30px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
									imageSettings={{
										src: QRImage,
										x: null,
										y: null,
										height: 50,
										width: 50,
										excavate: false,
									}}
								/>
								<div className={style.blankQR} />
								<div className={style.qrText}>ห้างหุ้นส่วนจำกัด มอร์ เซอร์วิสเซส</div>
								<div className={style.qrText}>(012356200382901)</div>
								<div className={style.qrText}>รายการสั่งซื้อ: {this.state.data.hex}</div>
								<div className={style.qrText}>ราคา: {this.getSum()} บาท</div>
							</React.Fragment>
						}
						<div className={style.spinCov}>
							<div className={style.loader} />
							<div className={style.spinText}>รอการชำระเงิน...</div>
						</div>
						<div className={style.spaceFix} />
						<div className={style.warFix}>หากชำระเงินแล้วรอนานเกิน 2 นาที <a href='/temp/fix.pdf' target='_blank' rel='noopenner noreferrer' className={style.linkFix}>โปรดคลิกเพื่ออ่านขั้นตอนการแก้ไข</a> นำผลลัพธ์มาใส่ในกล่องด้านล่าง แล้วกดตรวจสอบ</div>
						<div className={style.groupFix}>
							<input className={style.textFix} value={this.state.slip} onChange={(event) => this.setState({ slip: event.currentTarget.value })} type='text' />
							<div onClick={this.submitFix} className={style.submitFix}>ตรวจสอบ</div>
						</div>
						<div className={style.cartHeader}>รายการชีทที่ซื้อ</div>
						{this.renderCart()}
						{this.state.data.order.promoCode.length !== 0 && <div className={style.promoCode}>Promo code: {this.state.data.order.promoCode}</div>}
						{this.state.data.cart.length !== 0 && <div className={style.totalPrice}>{'รวม : ' + this.getSum() + ' บาท'}</div>}
					</Container>
					<div className={style.topBlank} />
				</div>
				<Footer />
			</React.Fragment>
		);
	}

	submitFix = async () => {
		var param = { qr: this.state.slip.trim() };
		var data = await Util.sendPost('/api/public/slipVerify', param);
		if (!data.success)
			this.props.alert.show('การตรวจสอบไม่สำเร็จ', { type: 'error' });
		else
			setTimeout(() => { this.props.history.push('/mysheet'); }, 1000);
	}

	getSum = () => {
		var now = this.state.data.order.price.toString();
		if (now.split('.').length == 1)
			return now + '.00';
		var last = now.split('.')[1];
		if (last.length == 2)
			return now;
		return now + '0';
	}

	renderCart = () => {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				{this.state.data.cart.map((obj) => (
					<React.Fragment key={obj._id}>
						<div className={style.sheetContain}>
							<div className={style.sheetLeft}>
								<Link target='_blank' rel='noopener noreferrer' to={'/infoSheet?' + obj._id}><img className={style.sheetCover} src={process.env.REACT_APP_SHEET_URL + obj._id + '/cover.jpg'} alt='cover'></img></Link>
								<div className={style.sheetLeftInfo}>
									<Link target='_blank' rel='noopener noreferrer' to={'/infoSheet?' + obj._id}><div className={style.sheetName}>{obj.name}</div></Link>
									<div className={style.sheetAuth}>{'By ' + obj.authorCode}</div>
								</div>
							</div>
							<div className={style.sheetRight}>
								<div className={style.sheetPrice}>{obj.price + ' บาท'}</div>
							</div>
						</div>
					</React.Fragment>))}
			</React.Fragment>
		);
	}

	checkPayment = async () => {
		var id = this.props.location.search.substring(1);
		var param = { id };
		var data = await Util.sendPost('/api/user/payOrder', param);
		if (data.success) {
			this.props.alert.show('พบรายการชำระเงิน ชีทจะเข้าในหน้าชีทของคุณภายใน 5 นาที', { type: 'success' });
			setTimeout(() => { this.props.history.push('/mysheet'); }, 1000);
		}
	}

	renderGo = () => {
		const lang = Util.getLang();
		if (this.state.data.user)
			return (<div className={style.goButt} onClick={() => this.checkPayment()}>
				ตรวจสอบการชำระเงินและรับชีท
			</div>);
	}

	verifyOrder = async () => {
		const lang = Util.getLang();
		var id = this.props.location.search.substring(1);
		var data = await Util.sendPost('/api/user/getOrder', { id });
		if (data.success) {
			await this.setState({ data: data.data });
		}
		else
			this.props.history.push('/mysheet');
	}

}

export default withRouter(withAlert()(Sell));