import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link , Redirect } from 'react-router-dom';
import FacebookLogin from '../../Components/FacebookLogin';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import style from './Style.module.css';
import logo from '../../asset/logo.png';
import google from '../../asset/google.png';
import Util from '../../Util';
import text from './text';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

class Login extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			email:false,
			fEmail:'',
			fPassword:'',
			loading:true,
			redirect:false,
			link:'/',
		};
	}
	
	async componentDidMount() {
		//if(window.location.search)
		//	await this.setState({link:window.location.search.substring(1)});
		var logged = await Util.sendPost('/api/public/getLogin',{});
		if(logged.success)
		{
			if(!logged.data.infoEnter)
				this.props.history.push('/infoEnter');
			else
				this.props.history.push('/');
			return;
		}
		var FBInterval = setInterval(()=>{
			if(window.moreSheetFacebookLoaded)
			{
				this.setState({loading:false});
				clearInterval(FBInterval);
			}
		},100);
	}

	render() {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				{this.state.redirect&&<Redirect to={this.state.link} />}
				{/*this.state.loading&&<div className={style.loader}></div>*/}
				<div className={style.shopPage}>
					<Nav />
					<div className={style.topBlank} />
					<div className={this.state.email?[style.loginBox,style.active].join(' '):style.loginBox}>
						<div className={style.header}>เข้าสู่ระบบด้วย Facebook, Google</div>
						<FacebookLogin loginFacebook={this.loginFacebook} />
						<GoogleLogin
							clientId={process.env.REACT_APP_GOOGLE}
							render={renderProps => (
							  <div className={style.goldButton} onClick={renderProps.onClick} disabled={renderProps.disabled}>
								<img className={style.gLogo} alt='' src={google}></img>
							  </div>
							)}
							buttonText='Login'
							onSuccess={this.responseGoogle}
							onFailure={this.responseGoogle}
							cookiePolicy={'single_host_origin'}
						/>
						<a href='https://moresheet.co/temp/tc.pdf' target='_blank' rel='noreferrer noopenner'><div className={style.terms}>การเข้าสู่ระบบด้วยปุ่มด้านบน หมายความว่าท่านยอมรับข้อตกลงการใช้บริการ คลิกเพื่ออ่าน</div></a>
						<a href='https://moresheet.co/temp/privacy.pdf' target='_blank' rel='noreferrer noopenner'><div className={style.terms}>การเข้าสู่ระบบด้วยปุ่มด้านบน หมายความว่าท่านยอมรับ Privacy Policy คลิกเพื่ออ่าน</div></a>
					</div>
				</div>
				<Footer />
			</React.Fragment>
		);
	}
	
	responseGoogle = async(response) => {
		if(response.error) {
			this.props.alert.show('Google เกิดข้อผิดพลาด กรุณาลองใหม่',{type: 'error'});
			return;
		}
		this.setState({loading:true});
		var logged = await Util.sendPost('/api/public/loginGoogle',{access_token:response.accessToken});
		Util.setToken(logged.token);
		this.setState({loading:false});
		if(!logged.infoEnter)
			this.props.history.push('/infoEnter');
		else
			this.props.history.goBack();
	}
	
	loginFacebook = () => {
		if(window.moreSheetFacebookLogged)
			this.sendFacebook(window.moreSheetFacebookToken);
		else {
			window.FB.login((response) => {
				if (response.authResponse) {
					var accessToken = response.authResponse.accessToken;
					this.props.alert.show('การเข้าสู่ระบบสำเร็จ รอสักครู่',{type: 'success'});
					this.sendFacebook(accessToken);
				} else {
					this.props.alert.show('การเข้าสู่ระบบไม่สำเร็จ',{type: 'error'});
				}
			});
		}
	}
	
	sendFacebook = async(accessToken) => {
		this.setState({loading:true});
		var logged = await Util.sendPost('/api/public/loginFacebook',{access_token:accessToken, ref: localStorage.getItem('buyRef')});
		Util.setToken(logged.token);
		this.setState({loading:false});
		if(!logged.infoEnter)
			this.props.history.push('/infoEnter');
		else
			this.props.history.goBack();
	}
}

export default withRouter(withAlert()(Login));