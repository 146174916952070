import React from 'react';
import Util from '../../Util';
import style from './Style.module.css';
import FavoriteSheet from '../../Components/FavoriteSheet';
import { Link , Redirect } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import {Visible,Hidden,Container} from 'react-grid-system';

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			open:false,
			sheet:[],
		};
	}
	

	render() {
		return (
			<React.Fragment>
				<div className={style.contain}>
					<div className={style.lineCon}>
						<div onClick={this.toggleState} className={style.name}>{this.state.open?'v ':'> '}{this.props.list.name}</div>
						<div className={style.editButton} onClick={this.editList}>แก้ไขชื่อ</div>
						<div className={style.deleteButton} onClick={this.deleteList}>ลบรายการออก</div>
					</div>
					<div className={style.sheetWrapper}>
						{this.state.open&&this.renderSheets()}
					</div>
				</div>
			</React.Fragment>
		);
	}
	
	toggleState = async(event) => {
		if(!this.state.open) {
			var data = await Util.sendPost('/api/user/listSheetFavorite',{id:this.props.list._id});
			if(data.success) {
				await this.setState({sheet:data.data.sheets});
			}
			else {
				this.props.alert.show(data.msg[0],{type: 'error'});
			}
		}
		await this.setState({open:!this.state.open});
	}
	
	renderSheets =()=>{
		if(this.state.sheet.length==0) {
			return (<div className={style.nothing}>ไม่พบชีทในรายการ</div>)
		}
		return this.state.sheet.map((sheet)=>
			(<FavoriteSheet sheet={sheet} key={sheet._id} store={{}} addCart={this.addCart} remove={this.remove} />));
	}
	
	addCart=async(id)=>{
		var data = await Util.sendPost('/api/public/ownSheet',{id});
		if(data.success) {
			var res = await Util.addCart(id);
			if(res)
				this.props.alert.show('เพิ่มชีทลงตะกร้าสำเร็จ',{type: 'success'});
			else
				this.props.alert.show('มีชีทนี้ในตะกร้าอยู่แล้ว',{type: 'error'});
		}
		else
			this.props.alert.show('คุณเป็นเจ้าของชีทนี้อยู่แล้ว',{type: 'error'});
	}
	
	remove=async(id,name)=> {
		if(window.confirm(`คุณแน่ใจว่าต้องการลบชีท '${name}' ออกจากรายการ '${this.props.list.name}?'`)) {
			var data = await Util.sendPost('/api/user/removeSheetFavorite',{id:this.props.list._id,sheet:id});
			if(data.success) {
				this.props.alert.show('ลบชีทออกจากรายการแล้ว',{type: 'success'});
				var data = await Util.sendPost('/api/user/listSheetFavorite',{id:this.props.list._id});
				if(data.success)
					await this.setState({sheet:data.data.sheets});
				else
					this.props.alert.show(data.msg[0],{type: 'error'});
			}
			else
				this.props.alert.show(data.msg[0],{type: 'error'});
		}
	}
	
	deleteList = async()=> {
		if(window.confirm(`คุณแน่ใจว่าต้องการลบรายการ '${this.props.list.name}?'`)) {
			var data = await Util.sendPost('/api/user/deleteFavorite',{id:this.props.list._id});
			if(data.success) {
				this.props.alert.show('ลบรายการแล้ว',{type: 'success'});
				this.props.reload();
			}
			else
				this.props.alert.show(data.msg[0],{type: 'error'});
		}
	}
	
	editList = async()=> {
		this.props.edit(this.props.list._id,this.props.list.name);
	}
}

export default withRouter(withAlert()(Sell));