import React from "react";
import { Link, Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import style from "./Style.module.css";
import org from "../../asset/org.jpg";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
//import './style.css'
class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const token = this.props.location.search.substring(1);
    window.localStorage.setItem("auth", token);
    window.location.href = "/";
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(withAlert()(EditSheet));
