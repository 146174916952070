import React from 'react';
import style from './Style.module.css';
import Util from '../../Util';
import {Hidden, Visible} from 'react-grid-system';
import { Link } from 'react-router-dom';
import star from '../../asset/star.png';


class SellSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		const cat=['','มิดเทอม ',' ไฟนอล '];
		return (
			<React.Fragment>
				<Hidden xs>
					<div className={style.wrapper}>
						<img className={style.cover} src={process.env.REACT_APP_SHEET_URL+this.props.sheet._id+'/cover.jpg'} alt='cover'></img>
						<div className={style.sold}>{'ถูกซื้อแล้ว '+this.props.sheet.bought+' ครั้ง'}</div>
						<a target='_blank' href={'/seller/editsheetafter?'+this.props.sheet._id}><div className={style.addCart}>แก้ข้อมูล</div></a>
					</div>
				</Hidden>
				<Visible xs>
					<div className={style.wrapperMobile}>
						<img className={style.coverMobile} src={process.env.REACT_APP_SHEET_URL+this.props.sheet._id+'/cover.jpg'} alt='cover'></img>
						<div>
							<div className={style.soldMobile}>{'ถูกซื้อแล้ว '+this.props.sheet.bought+' ครั้ง'}</div>
							<a target='_blank' href={'/seller/editsheetafter?'+this.props.sheet._id}><div className={style.infoMobile}>แก้ข้อมูล</div></a>
						</div>
					</div>
				</Visible>
			</React.Fragment>
		);
	}
	
	scoreText = (score,reviewer) => {
		if(reviewer===0)
			return 'ไม่มีคะแนน';
		var real=score;
		return real.toFixed(2);
	}
	
	dateMan = (date) => {
		var d=(new Date(date));
		return d.getDate().toString()+' / '+(d.getMonth()+1).toString()+' / '+d.getFullYear();
	}
}

export default SellSheet;