import React from 'react';
import style from './Style.module.css';
import Util from '../../Util';
import text from './text';
import logo from '../../asset/fb.png';


class FacebookLogin extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			uploading:false,
			updPdf:'',
		};
	}

	render() {
		if(this.state.uploading)
			return (<div className={style.uploading}>{this.state.updPdf}</div>);
		else
			return (<input className={style.file} type='file' onChange={(event)=>{this.uploadPdf(event.currentTarget.files[0])}}/>);
	}
	
	uploadPdf = async(file) => {
		await this.setState({updPdf:'เริ่มต้นอัพโหลด',uploading:true});
		var data = await Util.sendPost('/api/moresheetStaff/getUploadReUrl',{store:this.props.store,year:this.props.year,month:this.props.month,seller:this.props.author});
		var formData = new FormData();
		formData.append('acl', 'public-read');
		var keys = Object.keys(data.data.fields);
		for(var i=0;i<keys.length;i++)
			formData.append(keys[i],data.data.fields[keys[i]]);
		formData.append('file', file);
		//xhr
		var xhr = new XMLHttpRequest();
		xhr.open('POST', data.data.url, true);
		xhr.upload.onprogress = async(e)=> {
			var percent = Math.ceil((e.loaded / e.total) * 100);
			await this.setState({updPdf:'อัพโหลดแล้ว '+percent.toString()+'%'});
		};
		xhr.onreadystatechange = async() => { // Call a function when the state changes.
			if (xhr.readyState === XMLHttpRequest.DONE) {
				await this.setState({uploading:false});
				this.props.reload(this.props.num-1);
			}
		}
		xhr.send(formData);
	}
}

export default FacebookLogin;