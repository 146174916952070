import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import SellSheet from '../../Components/SellSheet';
import style from './Style.module.css';
import { Link , Redirect } from 'react-router-dom';
import {Container,Visible,Hidden} from 'react-grid-system';
import Footer from '../../Components/Footer';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';

const status=['ร่าง','กำลังตรวจสอบ','กำลังตรวจสอบ','ไม่ผ่าน'];

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			sheetID:'',
			data:{
				user:{
					infoEnter:true,
				},
				store:[],
			},
			sheets: {
				verf:[],
				unverf:[],
			},
			verfFil:[],
			filter:{
				sort:'date',
				search:'',
				sem:'any',
				year:'0',
			},
			storeMap:{
				
			}
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/seller/getSellMySheet',{});
		if(data.success)
		{
			await this.setState({data:data.data});
			var map={};
			for(var i=0;i<data.data.store.length;i++)
				map[data.data.store[i].code]={color:data.data.store[i].color,short:data.data.store[i].short};
			await this.setState({storeMap:map});
			this.reloadSheets();
		}
		else
			this.props.history.push('/seller');
	}

	render() {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				<div className={style.shopPage}>
					<Nav current='seller' user={this.state.data.user}/>
					<Container>
						<div className={style.blanker} />
						<div className={style.bigHeader}>ขายชีท</div>
						<div className={style.sellID}>{"รหัสผู้ขาย : "+this.state.data.user._id}</div>
						<div onClick={()=>this.newSheet()} className={style.newButton + " " + style.den}>เพิ่มชีทสรุปอันใหม่</div>
						<Link to='/seller/sellrev'><div className={style.newButton}>ประวัติรายได้</div></Link>
						<Link to='/seller/selledit'><div className={style.newButton}>แก้ไข/ดู นามปากกา,ที่อยู่,บัญชี</div></Link>
						<Link to='/seller/sellreport'><div className={style.newButton+" " + style.redder}>จัดการ Report</div></Link>
						<div className={style.header}>ชีทสรุปที่ยังไม่เผยแพร่</div>
						<hr />
						<div className={style.instruct}>คลิกที่ชื่อชีทเพื่อแก้ไขข้อมูล (เฉพาะชีทที่ไม่ได้อยู่ในขั้นตอนตรวจสอบ)</div>
						{this.renderUnverf()}
						<div className={style.header}>ชีทสรุปที่เผยแพร่แล้ว</div>
						<hr />
						<Visible xs sm>
							<div className={style.barMobile+' '+style.searcher}>
								<input type='text' placeholder={'ค้นหาตามชื่อ'} className={style.search+' '+style.mobile} value={this.state.filter.search} onChange={(event)=>this.setFilter('search',event.currentTarget.value)}/>
							</div>
							<div className={style.barMobile+' '+style.filter}>
								<select className={style.semSelector+' '+style.mobile} value={this.state.filter.sem} onChange={(event)=>this.setFilter('sem',event.currentTarget.value)}>{this.genTermOptions(lang)}</select>
								<select className={style.semSelector+' '+style.mobile+' '+style.last} value={this.state.filter.year} onChange={(event)=>this.setFilter('year',event.currentTarget.value)}>{this.genYearOptions(lang)}</select>
							</div>
							<div className={style.barMobile+' '+style.filter}>
								<select className={style.semSelector+' '+style.mobile+' '+style.sort} value={this.state.filter.sort} onChange={(event)=>this.setFilter('sort',event.currentTarget.value)}>{this.genSortOptions(lang)}</select>
							</div>
						</Visible>
						<Hidden xs sm>
							<div className={style.filterBar}>
								<input type='text' placeholder={'ค้นหาตามชื่อ'} className={style.search} value={this.state.filter.search} onChange={(event)=>this.setFilter('search',event.currentTarget.value)}/>
								<select className={style.semSelector} value={this.state.filter.sem} onChange={(event)=>this.setFilter('sem',event.currentTarget.value)}>{this.genTermOptions(lang)}</select>
								<select className={style.semSelector} value={this.state.filter.year} onChange={(event)=>this.setFilter('year',event.currentTarget.value)}>{this.genYearOptions(lang)}</select>
								<select className={style.semSelector} value={this.state.filter.sort} onChange={(event)=>this.setFilter('sort',event.currentTarget.value)}>{this.genSortOptions(lang)}</select>
							</div>
						</Hidden>
						<div className={style.sheetWrapper}>
							{this.renderVerf()}
						</div>
					</Container>
				</div>
				<Footer/>
			</React.Fragment>
		);
	}
	
	reloadSheets = async() => {
		var data = await Util.sendPost('/api/seller/getSellallSheet',{});
		var allSheets={verf:[],unverf:[]};
		for(var i=0;i<data.data.sheets.length;i++)
		{
			var sheet = data.data.sheets[i];
			if(sheet.status<4)
				allSheets.unverf.push(sheet);
			else
				allSheets.verf.push(sheet);
		}
		await this.setState({sheets:allSheets});
		await this.goFilter();
	}
	
	newSheet = async()=> {
		const lang = Util.getLang();
		var data = await Util.sendPost('/api/seller/newSheet',{});
		if(!data.success)
			return this.props.alert.show(data.msg[0],{type: 'error'});
		this.props.history.push('/seller/editSheet?'+data.data.id);
	}
	
	renderUnverf=()=>{
		const lang = Util.getLang();
		if(this.state.sheets.unverf.length===0)
			return (<div className={style.nothing}>ไม่พบรายการ</div>);
		return this.state.sheets.unverf.map((sheet) => {
				if(sheet.status===0||sheet.status===3)
					return (<Link to={'/seller/editSheet?'+sheet._id} key={sheet._id}><div className={style.unverf}>
						<div className={style.unverfName}>{sheet.name}</div>
						<div className={style['statusButt'+sheet.status.toString()]}>{status[sheet.status]}</div>
					</div></Link>)
				else
					return (<div className={style.unverf} key={sheet._id}>
					<div className={style.unverfName}>{sheet.name}</div>
					<div className={style['statusButt'+sheet.status.toString()]}>{status[sheet.status]}</div>
					</div>)
			}
		);
	}
	
	renderVerf=()=>{
		const lang = Util.getLang();
		if(this.state.verfFil.length===0)
			return (<div className={style.nothing}>ไม่พบรายการ</div>);
		return this.state.verfFil.map((sheet) => {
				return (
					<SellSheet sheet={sheet} key={sheet._id}>
					</SellSheet>)
			}
		);
	}
	
	genTermOptions = () => {
		const ops = [{label:'any',value:'any'},{label:'1',value:1},{label:'2',value:2},{label:'3',value:3}];
		return ops.map(({label,value}) => (
				<option label={label==='any'?'ทุกเทอม':label} value={value} key={value}>{label==='any'?'ทุกเทอม':label}</option>
			)
		);
	}
	
	genYearOptions = () => {
		var ops=[0];
		var curr = new Date().getFullYear();
		for(var i=0;i<10;i++)
			ops.push(curr-i);
		return ops.map((y) => (
				<option label={y===0?'ทุกปี':y} value={y} key={y}>{y===0?('ทุกปี'):y}</option>
			)
		);
	}
	
	genSortOptions = () => {
		const ops = [{label:'คะแนนมากขึ้นก่อน',value:'score'},{label:'คนซื้อมากขึ้นก่อน',value:'sold'},{label:'ล่าสุดขึ้นก่อน',value:'date'}];
		return ops.map(({label,value}) => (
				<option label={label} value={value} key={value}>{label}</option>
			)
		);
	}
	
	setFilter = async(field,val) => {
		var x=this.state.filter;
		x[field]=val;
		await this.setState({filter:x});
		await this.goFilter();
	}
	
	goFilter = async()=> {
		var res=[];
		for(var i=0;i<this.state.sheets.verf.length;i++) {
			var sheet=this.state.sheets.verf[i];
			if(this.state.filter.sem!=='any' && parseInt(this.state.filter.sem)!==sheet.semester)
				continue;
			if(this.state.filter.year!=='0' && parseInt(this.state.filter.year)!==sheet.year)
				continue;
			if(this.state.filter.search.trim()!=='' && !sheet.name.toLowerCase().includes(this.state.filter.search.trim().toLowerCase()))
				continue;
			sheet.created=(new Date(sheet.created)).getTime();
			res.push(sheet);
		}
		var sorter={
			score:function(a,b){
				return b.score-a.score;
			},
			sold:function(a,b){
				return b.bought-a.bought;
			},
			date:function(a,b){
				return b.created-a.created;
			}
		}
		res.sort(sorter[this.state.filter.sort]);
		await this.setState({verfFil:res});
	}
}

export default withRouter(withAlert()(Sell));