import React from "react";
import Nav from "../../Components/Nav";
import Quicksearch from "../../Components/QuickSearch";
import Footer from "../../Components/Footer";
import Fade from "react-reveal/Fade";
import wave from "../../asset/wave.svg";
import mattayom from "../../asset/mattayom.png";
import thai from "../../asset/thai.png";
import wavesmall from "../../asset/wavemobile.svg";
import man from "../../asset/man.svg";
import board from "../../asset/board.svg";
import { Link } from "react-router-dom";
import style from "./Style.module.css";
import Util from "../../Util";
import { Container, Visible, Hidden } from "react-grid-system";

import av1 from "../../asset/avatar1.png";
import av2 from "../../asset/avatar2.png";
import SpeechLeft from "../../asset/SpeechLeft.svg";
import SpeechRight from "../../asset/SpeechRight.svg";

import AppleIpadPro from "../../asset/apple_ipad_pro.jpg";
import AppleIpad from "../../asset/apple_ipad.jpg";
import AppleIphone from "../../asset/apple_iphone.jpg";
import AppleIphonePlus from "../../asset/apple_iphone_plus.jpg";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        count: {
          storeCount: 0,
          sheetCount: 0,
          sellCount: 0,
          orderCount: 0,
        },
        storeList: [],
      },
      stat: {
        uni: "12+",
        sheet: "1,202",
        seller: "234",
        trans: "5,764",
      },
      category: null,
    };
  }

  async componentDidMount() {
    var home = await Util.sendPost("/api/public/getHome", {});
    await this.setState({ data: home.data });
  }

  componentWillUnmount() {}

  render() {
    let screenWidth = window.screen.width;
    let pic, picwidth, picheight;
    console.log(screenWidth);
    if (screenWidth < 414) {
      pic = AppleIphone;
      picwidth = "375px";
      picheight = "81px";
    } else if (screenWidth < 720) {
      pic = AppleIphone;
      picwidth = "414px";
      picheight = "90px";
    } else if (screenWidth < 930) {
      pic = AppleIpad;
      picwidth = "720px";
      picheight = "155px";
    } else if (screenWidth <= 1030) {
      pic = AppleIpadPro;
      picwidth = "930px";
      picheight = "200px";
    } else {
      pic = AppleIpadPro;
      picwidth = "930px";
      picheight = "200px";
    }

    return (
      <React.Fragment>
        <Hidden xs sm>
          <div className={style.topSlide}>
            <Nav current="home" user={this.state.data.user} />
            <div className={style.topSpace} />
            <Fade right>
              <div className={style.waveContainer}>
                <img src={wave} className={style.wave} />
              </div>
            </Fade>
            <Fade left>
              <img src={board} className={style.board} />
            </Fade>
            <Fade left>
              <img src={man} className={style.man} />
            </Fade>
            <Container>
              <Fade top>
                <div className={style.bigHead}>
                  เพื่อนยามยาก
                  <br />
                  ในทุกการสอบ
                </div>
                <div className={style.smallHead}>
                  แหล่งรวมชีทสรุปสำหรับวิชาต่างๆ จากมหาวิทยาลัยทั่วประเทศ
                  <br />
                  สำหรับการติวสอบและการทบทวนในรายวิชานั้นๆ
                </div>
                <div className={style.buttonRow}>
                  <Link to="/shop">
                    <div className={style.buyButt}>เริ่มต้นซื้อชีท</div>
                  </Link>
                  <Link to="/seller">
                    <div className={style.sellButt}>เริ่มต้นขายชีท</div>
                  </Link>
                </div>
                <div className={style.buttonRow}>
                  <a
                    href="https://forms.gle/nGt9b3zT3uQ36a8T9"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    <div className={style.buyTicketButt}>Learning Space</div>
                  </a>
                  <div className={style.lsText}>
                    {"< ใหม่ ติวกับคนทำสรุปย้อนหลังได้เลย"}
                  </div>
                </div>
              </Fade>
            </Container>
          </div>
          <div className={style.slide2}>
            <Container>
              <div className={style.sectionM}>ค้นหาชีทที่คุณต้องการเลย</div>
              <Quicksearch />
              <div className={style.sectionM}>
                สถาบันที่มีเหล่าเพื่อนยามยากที่ร่วมแชร์สรุปในแพลตพอร์ม
              </div>
              <Fade right>
                <React.Fragment>
                  {/*<div className={style.uniContainer}>
									{this.renderUniPic(false)}
								</div>*/}
                  {this.state.category !== null && (
                    <React.Fragment>
                      <div
                        className={style.unicatback}
                        onClick={() => this.setState({ category: null })}
                      >
                        {"< ย้อนกลับ"}
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.category === null && (
                    <React.Fragment>
                      <div className={style.newUnicon}>
                        <div
                          className={style.unicat}
                          onClick={() => this.setState({ category: 0 })}
                        >
                          ภาคกลาง
                        </div>
                        <div
                          className={style.unicat}
                          onClick={() => this.setState({ category: 1 })}
                        >
                          ภาคเหนือ
                        </div>
                        <div
                          className={style.unicat}
                          onClick={() => this.setState({ category: 2 })}
                        >
                          ภาคใต้
                        </div>
                      </div>
                      <div className={style.newUnicon}>
                        <div
                          className={style.unicat}
                          onClick={() => this.setState({ category: 3 })}
                        >
                          ภาคอีสาน
                        </div>
                        <img
                          className={style.unicatmat}
                          src={mattayom}
                          onClick={() => this.setState({ category: 4 })}
                          alt=""
                        ></img>
                        <div
                          className={style.unicat}
                          onClick={() => this.setState({ category: 5 })}
                        >
                          ชีทหมวดอื่นๆ
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.category !== null && (
                    <div className={style.uniPicConNew}>
                      {this.renderUniPicNew()}
                    </div>
                  )}
                  <div className={style.newUni}>
                    <div className={style.newUniLabel}>
                      มองหามหาวิทยาลัยของคุณอยู่รึเปล่า?
                    </div>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf3vHKet_0hcEqAv2eIxwoIIQ8tscqeuRt1PSsKD_NZRXAjzw/viewform?usp=sf_link"
                      rel="noopenner noreferer"
                      target="_blank"
                    >
                      <div className={style.newUniLink}>ส่งคำร้อง</div>
                    </a>
                  </div>
                </React.Fragment>
              </Fade>
            </Container>
          </div>
          <div className={style.slide3}>
            <Container>
              <Fade left>
                <div className={style.allsaContainer}>
                  <div className={style.thaiContainer}>
                    <img src={thai} className={style.thai} />
                  </div>
                  <div className={style.statContainer}>
                    <div className={style.statBox}>
                      <div className={style.statValue}>
                        {this.state.data.count.storeCount.toString() + "+"}
                      </div>
                      <div className={style.statDescription}>
                        สถาบันการศึกษาทั่วประเทศ
                      </div>
                    </div>
                    <div className={style.statBox}>
                      <div className={style.statValue}>
                        {this.state.data.count.sellCount.toString() + "+"}
                      </div>
                      <div className={style.statDescription}>
                        นักสรุปที่พร้อมทำชีทส่งถึงมือคุณ
                      </div>
                    </div>
                    <div className={style.statBox}>
                      <div className={style.statValue}>
                        {this.state.data.count.sheetCount.toString() + "+"}
                      </div>
                      <div className={style.statDescription}>
                        จำนวนชีทในระบบจากหลายมหาวิทยาลัย
                      </div>
                    </div>
                    <div className={style.statBox}>
                      <div className={style.statValue}>
                        {this.state.data.count.orderCount.toString() + "+"}
                      </div>
                      <div className={style.statDescription}>
                        การสั่งซื้อที่เกิดขึ้นจริงในระบบ
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </Container>
          </div>
          {/*
					<div className={style.slide4}>
						<Container>
							<div className={style.expHeader}>
								ประสบการณ์<br />
								จากเพื่อนๆ นักศึกษา
							</div>


							<div className={style.reviewContainer}>
								<Fade right>
									<div className={style.speechLeft}>
										<div className={style.leftAvatar} style={{ backgroundImage: `url(${av1})`, backgroundSize: 'cover' }}>
										</div>
										<div className={style.leftBox} style={{ backgroundImage: `url(${SpeechLeft})`, backgroundSize: 'cover' }}>
											เรารู้จักจากเพื่อนมา ตอนแรกคิดว่าไม่น่าจะหา<br />
											ชีทเจอ แต่กลับเจอชีทที่สรุปตรงมากๆ<br />
											โดยรวมเจ๋งเลย! เราแนะนำ
										</div>
									</div>
								</Fade>

								<Fade left>
									<div className={style.speechRight}>
										<div className={style.rightBox} style={{ backgroundImage: `url(${SpeechRight})`, backgroundSize: 'cover' }}>
											ชีทสรุปค่อนข้างตรง เว็บใช้งานง่ายสะดวก<br />
											มากๆ อยากให้ลองใช้ดูครับ<br />#moresheet
										</div>
										<div className={style.rightAvatar} style={{ backgroundImage: `url(${av2})`, backgroundSize: 'cover' }}>
										</div>
									</div>
								</Fade>
							</div>

							<div className={style.readyHeader}>
								เราพร้อมเป็นเพื่อนยามยาก<br />
								ในทุกการสอบ
							</div>
							<div className={style.smallReady}>
								แหล่งรวมชีทสรุปสำหรับวิชาต่างๆ จากมหาวิทยาลัยทั่วประเทศ<br />
								สำหรับการติวสอบและการทบทวนในรายวิชานั้นๆ
							</div>
							<div className={style.buttonRowCenter}>
								<Link to='/shop'><div className={style.buyButt}>เริ่มต้นซื้อชีท</div></Link>
								<Link to='/seller'><div className={style.sellButt}>เริ่มต้นขายชีท</div></Link>
							</div>
						</Container>
					</div>
					*/}
        </Hidden>
        <Visible xs sm>
          <div className={style.topSlideSmall}>
            <Nav current="home" user={this.state.data.user} />
            <div className={style.topSpaceSmall} />
            <Fade right>
              <div className={style.waveContainerSmall}>
                <img src={wavesmall} className={style.waveSmall} />
              </div>
            </Fade>
            <Container>
              <Fade top>
                <div className={style.bigHeadSmall}>
                  เพื่อนยามยาก
                  <br />
                  ในทุกการสอบ
                </div>
                <div className={style.smallHeadSmall}>
                  แหล่งรวมชีทสรุปสำหรับวิชาต่างๆ
                  <br />
                  จากมหาวิทยาลัยทั่วประเทศ
                  <br />
                  สำหรับการติวสอบและการทบกวนในรายวิชานั้นๆ
                </div>
                <Link to="/shop">
                  <div className={style.buyButtSmall}>เริ่มต้นซื้อชีท</div>
                </Link>
                <Link to="/seller">
                  <div className={style.sellButtSmall}>เริ่มต้นขายชีท</div>
                </Link>
                <a
                  href="https://forms.gle/nGt9b3zT3uQ36a8T9"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <div className={style.sellButtSmall}>
                    ซื้อ Ticket Learning Space
                  </div>
                </a>
              </Fade>
            </Container>
          </div>
          <div className={style.slide2Small}>
            <Container>
              <Fade right>
                <div className={style.uniContainerSmall}>
                  {this.renderUniPic(true)}
                </div>
                <div className={style.newUniSmall}>
                  <div className={style.newUniLabelSmall}>
                    มองหามหาวิทยาลัยของคุณอยู่รึเปล่า?
                  </div>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSf3vHKet_0hcEqAv2eIxwoIIQ8tscqeuRt1PSsKD_NZRXAjzw/viewform?usp=sf_link"
                    target="_blank"
                    rel="noopenner noreferer"
                  >
                    <div className={style.newUniLinkSmall}>ส่งคำร้อง</div>
                  </a>
                </div>
              </Fade>
            </Container>
          </div>
          <div className={style.slide3Small}>
            <Container>
              <Fade left>
                <div className={style.statContainerSmall}>
                  <div className={style.statBoxSmall}>
                    <div className={style.statValueSmall}>
                      {this.state.data.count.storeCount.toString() + "+"}
                    </div>
                    <div className={style.statDescriptionSmall}>
                      มหาวิทยาลัย
                      <br />
                      ที่เข้าร่วม
                    </div>
                  </div>
                  <div className={style.statBoxSmall}>
                    <div className={style.statValueSmall}>
                      {this.state.data.count.sheetCount.toString() + "+"}
                    </div>
                    <div className={style.statDescriptionSmall}>
                      ชีทจากหลากหลาย
                      <br />
                      มหาวิทยาลัยในระบบ
                    </div>
                  </div>
                  <div className={style.statBoxSmall}>
                    <div className={style.statValueSmall}>
                      {this.state.data.count.sellCount.toString() + "+"}
                    </div>
                    <div className={style.statDescriptionSmall}>
                      นักสรุปที่พร้อมทำชีท
                      <br />
                      ส่งถึงมือคุณ
                    </div>
                  </div>
                  <div className={style.statBoxSmall}>
                    <div className={style.statValueSmall}>
                      {this.state.data.count.orderCount.toString() + "+"}
                    </div>
                    <div className={style.statDescriptionSmall}>
                      การสั่งซื้อ
                      <br />
                      จากผู้ใช้จริง
                    </div>
                  </div>
                </div>
              </Fade>
            </Container>
          </div>
          <div className={style.slide4Small}>
            <Container>
              <div className={style.expHeaderSmall}>
                ประสบการณ์
                <br />
                จากเพื่อนๆ นักศึกษา
              </div>

              <div className={style.reviewContainerSmall}>
                <Fade right>
                  <div className={style.speechLeftSmall}>
                    <div
                      className={style.leftBoxSmall}
                      style={{
                        backgroundImage: `url(${SpeechLeft})`,
                        backgroundSize: "cover",
                      }}
                    >
                      เรารู้จักจากเพื่อนมา ตอนแรกคิดว่าไม่น่าจะ
                      <br />
                      หาชีทเจอ แต่กลับเจอชีทที่สรุปตรงมากๆ
                      <br />
                      โดยรวมเจ๋งเลย! เราแนะนำ
                    </div>
                  </div>
                </Fade>

                <Fade left>
                  <div className={style.speechRightSmall}>
                    <div
                      className={style.rightBoxSmall}
                      style={{
                        backgroundImage: `url(${SpeechRight})`,
                        backgroundSize: "cover",
                      }}
                    >
                      ชีทสรุปค่อนข้างตรง เว็บใช้งานง่ายสะดวก
                      <br />
                      มากๆ อยากให้ลองใช้ดูครับ
                      <br />
                      #moresheet
                    </div>
                  </div>
                </Fade>
              </div>

              <div className={style.readyHeaderSmall}>
                เราพร้อมเป็นเพื่อนยามยาก
                <br />
                ในทุกการสอบ
              </div>
              <div className={style.smallReadySmall}>
                แหล่งรวมชีทสรุปสำหรับวิชาต่างๆ
                <br />
                จากมหาวิทยาลัยทั่วประเทศ
                <br />
                สำหรับการติวสอบและการทบกวนในรายวิชานั้นๆ
              </div>
              <Link to="/shop">
                <div className={style.buyButtSmallCenter}>เริ่มต้นซื้อชีท</div>
              </Link>
              <Link to="/seller">
                <div className={style.sellButtSmallCenter}>เริ่มต้นขายชีท</div>
              </Link>
            </Container>
          </div>
        </Visible>

        {/*<Footer />*/}
      </React.Fragment>
    );
  }

  renderUniPic = (small) => {
    var out = [];
    for (var i = 0; i < this.state.data.storeList.length; i++) {
      var code = this.state.data.storeList[i].code;
      out.push(
        <Link to={"/shop?" + code.toString()} key={code}>
          <img
            src={process.env.REACT_APP_LOGO_URL + code.toString() + ".jpg"}
            className={small ? style.uniPicSmall : style.uniPic}
            alt=""
          />
        </Link>
      );
    }
    return out;
  };

  renderUniPicNew = (small) => {
    var out = [];
    for (var i = 0; i < this.state.data.storeList.length; i++) {
      if (this.state.data.storeList[i].category !== this.state.category)
        continue;
      var code = this.state.data.storeList[i].code;
      out.push(
        <Link to={"/shop?" + code.toString()} key={code}>
          <img
            src={process.env.REACT_APP_LOGO_URL + code.toString() + ".jpg"}
            className={small ? style.uniPicSmall : style.uniPic}
            alt=""
          />
        </Link>
      );
    }
    return out;
  };
}

export default Home;
