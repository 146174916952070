import React from "react";
import { Link, Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import style from "./Style.module.css";
import org from "../../asset/org.jpg";
import thep from "../../asset/thep.png";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
//import './style.css';
const monthName = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];
class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      out: [],
      month: parseInt(props.location.search.substring(1).split("&")[0]),
      year: parseInt(props.location.search.substring(1).split("&")[1]),
    };
  }

  async componentDidMount() {
    var data = await Util.sendPost("/api/moresheetStaff/getTaxPay", {
      month: this.state.month,
      year: this.state.year,
    });
    if (data.success) {
      for (let i = 0; i < data.data.length; i++) {
        if (!data.data[i].seller[0]) console.log(data.data[i]);
      }
      await this.setState({ out: data.data });
    } else this.props.history.push("/staff");
  }

  render() {
    return (
      <React.Fragment>
        {this.state.out.map((data, i) => {
          return this.renderPage(data, i);
        })}
      </React.Fragment>
    );
  }

  renderPage = (data, index) => {
    if (data.total * 0.6 < 1000) return <React.Fragment key={index} />;
    if (!data.seller[0]) return <React.Fragment key={index} />;
    return (
      <div className={style.page} key={index}>
        <div>
          <div className={style.boldIL}>ฉบับที่ 1 </div>
          <div className={style.normalIL}>
            (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)
          </div>
          <div className={style.topBlank} />
          <div className={style.boldIL}>ฉบับที่ 2 </div>
          <div className={style.normalIL}>
            (สำหรับผู้ถูกหักภาษี ณ ที่จ่ายเก็บไว้เป็นหลักฐาน)
          </div>
        </div>
        <div className={style.second}>
          <div className={style.boldIL}>หนังสือรับรองการหักภาษี ณ ที่จ่าย</div>
          <div className={style.normalIL}>
            {" "}
            ตามมาตรา 50 ทวิ แห่งประมวลรัษฏากร
          </div>
          <div className={style.secondNum}>เล่มที่</div>
          <div className={style.numValue}>{"M06"}</div>
          <div className={style.secondDate}>เลขที่</div>
          <div className={style.numValue}>0-{index}</div>
        </div>
        <div className={style.inner}>
          <div className={style.innerus}>
            <div className={style.us}>
              <div className={style.boldILHead}>
                ผู้มีหน้าที่หักภาษี ณ ที่จ่าย :-
              </div>
              <div className={style.normalUs}>เลขประจำตัวประชาชน</div>
              <div className={style.numValueUs}>-</div>
            </div>
            <div className={style.us2}>
              <div className={style.normalLeft}>ชื่อ</div>
              <div className={style.numValueUs}>
                บริษัท มอร์ เซอร์วิสเซส จำกัด
              </div>
            </div>
            <div className={style.us3}>
              <div className={style.normalSmall}>
                (ให้ระบุว่าเป็น บุคคล นิติบุคคล บริษัท สมาคม หรือคณะบุคคล)
              </div>
              <div className={style.normalUs3}>เลขประจำตัวผู้เสียภาษีอากร</div>
              <div className={style.numValueUs}>0125566010007</div>
            </div>
            <div className={style.us2}>
              <div className={style.normalLeft}>ที่อยู่</div>
              <div
                className={style.numValueUsAdd}
                style={{
                  fontSize: "10px",
                }}
              >
                1788 อาคารสิงห์คอมเพล็กซ์ ชั้นที่ 30 ยูนิต 3001-3003,3009-3014
                ถนนเพชรบุรีตัดใหม่ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร
              </div>
            </div>
            <div className={style.us3}>
              <div className={style.normalSmall}>
                (ให้ระบุ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต
                จังหวัด)
              </div>
            </div>
          </div>
          <div className={style.innerus}>
            <div className={style.us}>
              <div className={style.boldILHead}>ผู้ถูกหักภาษี ณ ที่จ่าย :-</div>
              <div className={style.normalUs2}>เลขประจำตัวประชาชน</div>
              <div className={style.numValueUs}>
                {data.seller[0].peopleID
                  ? data.seller[0].peopleID.substring(0, 13)
                  : ""}
              </div>
            </div>
            <div className={style.us2}>
              <div className={style.normalLeft}>ชื่อ</div>
              <div className={style.numValueUs}>{data.seller[0].realName}</div>
            </div>
            <div className={style.us3}>
              <div className={style.normalSmall}>
                (ให้ระบุว่าเป็น บุคคล นิติบุคคล บริษัท สมาคม หรือคณะบุคคล)
              </div>
              <div className={style.normalUs3}>เลขประจำตัวผู้เสียภาษีอากร</div>
              <div className={style.numValueUs}>-</div>
            </div>
            <div className={style.us2}>
              <div className={style.normalLeft}>ที่อยู่</div>
              <div className={style.numValueUsAdd}>
                {data.seller[0].addSmall +
                  " " +
                  data.seller[0].addSmallDistrict +
                  " " +
                  data.seller[0].addDistrict +
                  " " +
                  data.seller[0].addProvince}
              </div>
            </div>
            <div className={style.us3}>
              <div className={style.normalSmall}>
                (ให้ระบุ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต
                จังหวัด)
              </div>
            </div>
          </div>
          <div className={style.innerus3}>
            <div className={style.us2}>
              <div className={style.normalLeft}>ลำดับที่</div>
              <div className={style.numValue}>0-{index}</div>
              <div className={style.normalLeft}>ใบแนบ</div>

              <div className={style.checkList}>[ ] (1) ภ.ง.ด. 1 ก.</div>
              <div className={style.checkList}>[ ] (2) ภ.ง.ด. 1 ก. พิเศษ</div>
              <div className={style.checkList}>[ ] (3) ภ.ง.ด. 2</div>
              <div className={style.checkList}>[X] (4) ภ.ง.ด. 3</div>
            </div>
            <div className={style.us4}>
              <div className={style.checkList}>[ ] (5) ภ.ง.ด. 2 ก.</div>
              <div className={style.checkList}>[ ] (6) ภ.ง.ด. 3 ก.</div>
              <div className={style.checkList}>[ ] (7) ภ.ง.ด. 53</div>
            </div>
          </div>
          <div className={style.innerus4}>
            <div className={style.column1}>
              <div className={style.topCol}>
                <div className={style.topText}>ประเภทเงินได้ที่จ่าย</div>
              </div>
              <div className={style.lineStart}>
                1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40 (1)
              </div>
              <div className={style.lineStart}>
                2. ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)
              </div>
              <div className={style.lineStart}>
                3. ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40 (3)
              </div>
              <div className={style.lineStart}>
                4. (ก) ค่าดอกเบี้ย ฯลฯ ตามมาตรา 40 (4) (ก)
              </div>
              <div className={style.lineTab}>
                (ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40 (4) (ข)
              </div>
              <div className={style.lineTabTab}>
                (1) กิจการที่ต้องเสียภาษีเงินได้นิติบุคลในอัตราดังนี้
              </div>
              <div className={style.lineTabTabTab}>
                (1.1) อัตราร้อยละ 30 ของกำไรสุทธิ
              </div>
              <div className={style.lineTabTabTab}>
                (1.2) อัตราร้อยละ 25 ของกำไรสุทธิ
              </div>
              <div className={style.lineTabTabTab}>
                (1.3) อัตราร้อยละ 20 ของกำไรสุทธิ
              </div>
              <div className={style.lineTabTabTab}>
                (1.4) อัตราอื่นๆ (ระบุ)………………ของกำไรสุทธิ
              </div>
              <div className={style.lineStart}>
                5. การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่ายตามคำสั่งกรมสรรพากร
              </div>
              <div className={style.lineTab}>
                ที่ออกตามมาตรา 3 เตรส เช่น ค่าซื้อพืชผลทางการเกษตร
              </div>
              <div className={style.lineTab}>
                (ยางพารา มันสำปะหลัง ปอ ข้าว ฯลฯ) รางวัลในการประกวด
              </div>
              <div className={style.lineTab}>
                การแข่งขัน การชิงโชค ร้องเพลง ค่าแสดงภาพยนตร์ ค่าบริการ
              </div>
              <div className={style.lineTab}>
                ดนตรี ค่าจ้างทำของ ค่าจ้างโฆษณา ค่าเช่า ฯลฯ
              </div>
              <div className={style.lineStart}>
                6. อื่น ๆ (ระบุ).................................
              </div>
            </div>
            <div className={style.column2}>
              <div className={style.topCol}>
                <div className={style.topText}>
                  วัน เดือน หรือ
                  <br />
                  ปีภาษีที่จ่าย
                </div>
              </div>
              <div className={style.valueCol}>
                {"04/" + this.getNextMonth() + "/" + this.getNextYear()}
              </div>
            </div>
            <div className={style.column3}>
              <div className={style.topCol}>
                <div className={style.topText}>จำนวนเงินที่จ่าย</div>
              </div>
              <div className={style.valueCol}>
                {(data.total * 0.6 * (97 / 100)).toFixed(2)}
              </div>
            </div>
            <div className={style.column4}>
              <div className={style.topCol}>
                <div className={style.topText}>ภาษีที่หักและนำส่งให้</div>
              </div>
              <div className={style.valueCol}>
                {(data.total * 0.6 * (3 / 100)).toFixed(2)}
              </div>
            </div>
          </div>
          <div className={style.innerus5}>
            <div className={style.column1x}>
              รวมเงินที่จ่ายและภาษีที่หักนำส่ง
            </div>
            <div className={style.column2x}>
              {(data.total * 0.6 * (97 / 100)).toFixed(2)}
            </div>
            <div className={style.column3x}>
              {(data.total * 0.6 * (3 / 100)).toFixed(2)}
            </div>
          </div>
          <div className={style.innerus5}>
            <div className={style.column1xx}>
              รวมเงินภาษีที่หักนำส่ง (ตัวอักษร)
            </div>
            <div className={style.column2xx}>
              {Util.bahttext((data.total * 0.6 * (3 / 100)).toFixed(2))}
            </div>
          </div>
          <div className={style.innerus6}>
            เงินที่จ่ายเข้า
            กบข./กสจ./กองทุนสงเคราะห์ครูโรงเรียนเอกชน................บาท
            กองทุนประกันสังคม..............บาท
            กองทุนสำรองเลี้ยงชีพ...................บาท
          </div>
          <div className={style.innerus5}>
            <div className={style.column1xxx}>ผู้จ่ายเงิน</div>
            <div className={style.column2xxx}>
              <div className={style.boxf}></div>
              (1) หักภาษี ณ ที่จ่าย
            </div>
            <div className={style.column2xxx}>
              <div className={style.boxn}></div>
              (2) ออกภาษีให้ตลอดไป
            </div>
            <div className={style.column2xxx}>
              <div className={style.boxn}></div>
              (3) ออกภาษีให้ครั้งเดียว
            </div>
            <div className={style.column2xxx}>
              <div className={style.boxn}></div>
              (4) อื่นๆ (ระบุ)………
            </div>
          </div>
          <div className={style.botText}>
            ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้นถูกต้องตรงกับความจริงทุกประการ
          </div>
          <div className={style.botText2}>
            <div className={style.botTextLeftLeft}>ลงชื่อ</div>
            <div className={style.botTextLeft}>
              <img src={thep} className={style.botTextLeftPic} />
              ..................................................
            </div>
            <div className={style.botTextRight}>
              ผู้มีหน้าที่หักภาษี ณ ที่จ่าย
            </div>
          </div>
          <div className={style.botText3}>( นาย อดิเทพ ปัสรีจา )</div>
          <div className={style.botText4}>
            {"04/" + this.getNextMonth() + "/" + this.getNextYear()}
          </div>
        </div>
        <div className={style.botText5}>
          หมายเหตุ *
          ให้สามารถอ้างอิงหรือสอบยันกันได้ระหว่างลำดับที่ตามหนังสือรับรองฯ
          กับแบบยื่นรายการภาษีหัก ณ ที่จ่าย
        </div>
        <div className={style.botText5}>
          คำเตือน ผู้มีหน้าที่ออกหนังสือรับรองการหักภาษี ณ
          ที่จ่ายฝ่าฝืนไม่ปฏิบัติตามมาตรา 50 ทวิ แห่งประมวณรัษฎากร
          ต้องรับโทษทางอาญาตามมาตรา35 แห่งประมวลรัษฎากร
        </div>
      </div>
    );
  };

  getNextMonth = () => {
    if (this.state.month + 1 > 12) return "01";
    else {
      if (this.state.month + 1 < 10)
        return "0" + (this.state.month + 1).toString();
      else return this.state.month + 1;
    }
  };

  getNextYear = () => {
    if (this.state.month + 1 > 12) return this.state.year + 544;
    else return this.state.year + 543;
  };
}

export default withRouter(withAlert()(EditSheet));
