import React from 'react';
import { Link , Redirect } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import Util from '../../Util';
import style from './Style.module.css';
import org from '../../asset/org.jpg';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
//import './style.css';
const monthName=['มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน','กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'];
class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			out:[],
			month:parseInt(props.location.search.substring(1).split('&')[0]),
			year:parseInt(props.location.search.substring(1).split('&')[1])
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/moresheetStaff/getTaxPay',{month:this.state.month,year:this.state.year});
		if(data.success)
			await this.setState({out:data.data});
		else
			this.props.history.push('/staff');
	}

	render() {
		return (
			<div className={style.page}>
				<div className={style.inner}>
					<img className={style.logo} alt=''  src={org}/>
					<div className={style.header}>รายจ่าย หจ. มอร์ เซอร์วิสเซส</div>
					<div className={style.header2}>102/106 หมู่ที่ 8 ต.บางเลน อ.บางใหญ่ จ.นนทบุรี</div>
					<div className={style.header3}>โทร. 0972983260 เลขประจำตัวผู้เสียภาษี 0123562003829</div>
					<div className={style.local1}>เลขที่ M-{this.state.month}-{this.state.year}</div>
					<div className={style.local1}>ประจำเดือน {monthName[this.state.month-1]} พ.ศ.{this.state.year+543}</div>
					<table className={style.table}>
						<thead>
							<tr>
								<th>ลำดับ</th>
								<th>รายการ</th>
								<th>จำนวนเงิน (บาท)</th>
							</tr>
						</thead>
						<tbody>
							{this.renderTab()}
						</tbody>
					</table>
					<div className={style.header4}>รวม {this.renderTotal().toFixed(2)} บาท</div>
				</div>
			</div>
		);
	}
	
	renderTab=()=>{
		return this.state.out.map((data,i)=>{
			return (
				<tr key={i}>
					<td>{i+1}</td>
					<td>ค่าจ้างทำชีทสรุป {data.seller[0].realName}</td>
					<td>{(data.total*0.6).toFixed(2)}</td>
				</tr>
			)
		})
	}
	
	renderTotal=()=>{
		var sum=0;
		this.state.out.map((data)=>{
			sum+=data.total*0.6;
		});
		return sum;
	}
	
}

export default withRouter(withAlert()(EditSheet));