import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Info from "./Pages/InfoEnter";
import Shop from "./Pages/Shop";
import SecretShop from "./Pages/SecretShop";
import Sell from "./Pages/Sell";
import Faq from "./Pages/Faq";
import SellRegister from "./Pages/SellRegister";
import SellEdit from "./Pages/SellEdit";
import SellEditSheet from "./Pages/SellEditSheet";
import SellCalPrice from "./Pages/SellCalPrice";
import SellMySheet from "./Pages/SellMySheet";
import SellReport from "./Pages/SellReport";
import SellRev from "./Pages/SellRev";
import SellSlip from "./Pages/SellSlip";
import MySheet from "./Pages/MySheet";
import Favorite from "./Pages/Favorite";
import ReadSheet from "./Pages/ReadSheet";
import EditSheet from "./Pages/EditSheet";
import Cart from "./Pages/Cart";
import Order from "./Pages/Order";
import InfoSheet from "./Pages/InfoSheet";
import InfoGroup from "./Pages/InfoGroup";
import StaffVerifySheet from "./Pages/StaffVerifySheet";
import StaffPayment from "./Pages/StaffPayment";
import StaffStat from "./Pages/StaffStat";
import StaffReport from "./Pages/StaffReport";
import StaffHome from "./Pages/StaffHome";
import StaffAuthor from "./Pages/StaffAuthor";
import StaffDownloadApprovedcover from "./Pages/StaffDownloadApprovedcover";
import StaffSheet from "./Pages/StaffSheetHome";
import StaffEditSheet from "./Pages/StaffEditSheet";
import StaffListUni from "./Pages/StaffListUni";
import StaffEditUni from "./Pages/StaffEditUni";
import StaffEditDrive from "./Pages/StaffEditDrive";
import SampleRe from "./Pages/SampleRe";
import SampleRe2 from "./Pages/SampleRe2";
import WorkaroundOut from "./Pages/WorkaroundOut/WorkaroundOut";
import SampleOut from "./Pages/SampleOut";
import SampleWith from "./Pages/SampleWith";
import SampleOutAll from "./Pages/SampleOutAll";
import SampleOutAll2 from "./Pages/SampleOutAll2";
import SinghDrive from "./Pages/SinghDrive";
import Message from "./Pages/Message";
import mob from "./asset/mobile.jpg";

import Util from "./Util";

import "./route.css";

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/login", name: "About", Component: Login },
  { path: "/shop", name: "Contact", Component: Shop },
  { path: "/secretshop", name: "secret", Component: SecretShop },
  { path: "/infoEnter", name: "InfoEnter", Component: Info },
  { path: "/infoGroup", name: "InfoGroup", Component: InfoGroup },
  { path: "/seller", name: "Sell", Component: Sell },
  { path: "/faq", name: "Faq", Component: Faq },
  { path: "/seller/editSheet", name: "editSheet", Component: EditSheet },
  {
    path: "/staff/verifySheet",
    name: "verifySheet",
    Component: StaffVerifySheet,
  },
  {
    path: "/staff/downloadapprovedcover",
    name: "downloadapprovedcover",
    Component: StaffDownloadApprovedcover,
  },
  { path: "/staff/payment", name: "payment", Component: StaffPayment },
  { path: "/staff/stat", name: "stat", Component: StaffStat },
  { path: "/staff/report", name: "report", Component: StaffReport },
  { path: "/staff", name: "staffHome", Component: StaffHome },
  { path: "/staff/author", name: "staffAuthor", Component: StaffAuthor },
  { path: "/staff/sheetInfo", name: "staffSheet", Component: StaffSheet },
  {
    path: "/staff/sheetEdit",
    name: "staffEditSheet",
    Component: StaffEditSheet,
  },
  { path: "/staff/SampleRe", name: "sample", Component: SampleRe },
  { path: "/staff/SampleOut", name: "sampleOut", Component: SampleOut },
  {
    path: "/staff/SampleOutAll",
    name: "sampleOutAll",
    Component: SampleOutAll,
  },
  { path: "/staff/unison", name: "unison", Component: StaffListUni },
  { path: "/staff/edituni", name: "editUni", Component: StaffEditUni },
  { path: "/staff/editdrive", name: "editDrive", Component: StaffEditDrive },
  { path: "/seller/register", name: "registerSell", Component: SellRegister },
  { path: "/seller/mysheet", name: "sellMysheet", Component: SellMySheet },
  { path: "/seller/sellreport", name: "sellReport", Component: SellReport },
  { path: "/seller/sellrev", name: "sellrev", Component: SellRev },
  { path: "/seller/selledit", name: "selledit", Component: SellEdit },
  { path: "/seller/pricecal", name: "pricecal", Component: SellCalPrice },
  {
    path: "/seller/editSheetAfter",
    name: "selleditsheet",
    Component: SellEditSheet,
  },
  { path: "/seller/slip", name: "sellslip", Component: SellSlip },
  { path: "/singhdrive", name: "singhdrive", Component: SinghDrive },
  { path: "/cart", name: "cart", Component: Cart },
  { path: "/user/myorder", name: "order", Component: Order },
  { path: "/infoSheet/:sheetId", name: "infoSheet", Component: InfoSheet },
  { path: "/infoSheet", name: "infoSheet", Component: InfoSheet },
  { path: "/mysheet", name: "mysheet", Component: MySheet },
  { path: "/favorite", name: "favorite", Component: Favorite },
  { path: "/message", name: "message", Component: Message },
  { path: "/user/readSheet", name: "readsheet", Component: ReadSheet },
  {
    path: "/workaroundlogin",
    name: "workaround",
    Component: WorkaroundOut,
  },
];

const debugroute = [
  { path: "/staff/SampleRe2", name: "sample2", Component: SampleRe2 },
  { path: "/staff/SampleWith", name: "sampleWith", Component: SampleWith },
  {
    path: "/staff/SampleOutAll2",
    name: "sampleOutAll2",
    Component: SampleOutAll2,
  },
];

const options = {
  position: positions.TOP_CENTER,
  timeout: 2000,
  offset: "20px",
  transition: transitions.SCALE,
};

async function checkAck() {
  if (localStorage.getItem("ack") == "1") return;
  var data = await Util.sendPost("/api/seller/getAckTax", {});
  if (data.success) {
    await alert(
      "ประกาศถึงผู้ขายทุกท่าน เพื่อทำให้การรับรายได้และแก้ไขปัญหาต่างๆเป็นไปได้สะดวก กรุณาอัพเดตข้อมูลติดต่อของท่าน โดยเราจะนำท่านไปยังหน้าแก้ไขโดยอัตโนมัติหลังปิดข้อความนี้"
    );
    await localStorage.setItem("ack", "1");
    await window.location.assign("/seller/selledit");
  }
}

function RouteWrap() {
  var rr = routes;
  if (process.env.REACT_APP_DEBUG == "1") rr = rr.concat(debugroute);
  if (window.detectAppBrowser()) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <div
          style={{
            fontFamily: "Mitr",
            fontSize: 25,
            textAlign: "center",
          }}
        >
          ขั้นตอนง่ายๆ ก่อนใช้ <br />
          moresheet.co บนโทรศัพท์มือถือ
        </div>
        <img
          alt=""
          style={{
            width: "325px",
            height: "325px",
          }}
          src={mob}
        ></img>
      </div>
    );
  }
  return (
    <Router>
      {rr.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => {
            if (match) {
              checkAck();
            }
            return (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="MoreSheetPage"
                unmountOnExit
              >
                <div className="MoreSheetPage">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <Component />
                  </AlertProvider>
                </div>
              </CSSTransition>
            );
          }}
        </Route>
      ))}
    </Router>
  );
}

export default RouteWrap;
