import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import style from './Style.module.css';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-grid-system';

class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			redirect: false,
			data: {
				user: { infoEnter: true },
				sheet: {
					store: 0,
					name: '',
					classInfo: '',
					detail: '',
					price: '',
					examNum: 0,
					id: '',
					semester: 1,
					year: 2019,
					category: 0,
					visible: false,
				},
				store: []
			},
			sCode: '',
			sName: '',
			cover: false,
			saving: false,
		};
	}

	async componentDidMount() {
		var id = window.location.search.substring(1);
		var data = await Util.sendPost('/api/seller/getEdit', { id });
		if (!data.success)
			return this.props.history.push('/seller/mysheet');
		var name = data.data.sheet.name;
		await this.setState({ sCode: name.substr(0, name.indexOf(' ')), sName: name.substr(name.indexOf(' ') + 1) });
		await this.setState({ data: data.data });
		await this.setState({ data: data.data });
		await this.reloadCover(id);
	}

	render() {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				<div className={style.sheetPage}>
					<Nav current='seller' user={this.state.data.user} />
					<Container>
						<div className={style.container}>
							<div className={style.blankTop} />
							<div className={style.header}>แก้ไขชีทที่ลงขายแล้ว</div>

							{this.state.data.sheet.status === 3 &&
								<div className={style.reject}>
									<div className={style.rejectHeader}>ชีทถูกปฏิเสธ เนื่องจาก :</div>
									<div className={style.rejectReason}>
										{this.nl2br(this.state.data.sheet.reason)}
									</div>
								</div>
							}

							<div className={style.label}>มหาวิทยาลัยที่ต้องการเผยแพร่ชีทนี้</div>
							<div className={style.explainLabel}>สามารถพิมพ์ชื่อมหาวิทยาลัยเพื่อค้นหาได้</div>
							<SelectSearch className='select-search-box-editSheet' options={this.genUniOptions()} value={this.state.data.sheet.store.toString()} onChange={(value) => this.setUni(value)} />

							{this.state.data.sheet.store != 999999 && this.state.data.sheet.store != 9997  &&
								<React.Fragment>
									<div className={style.label}>ชนิดของชีทสรุป</div>
									<div className={style.explainLabel}>มิดเทอม, ไฟนอล หรืออื่น ๆ (ทั้งเทอม etc..)</div>
									<select className={style['textField']} value={this.state.data.sheet.category} onChange={(event) => { this.setField('category', event.currentTarget.value) }} >
										{this.genCatOptions()}
									</select>
								</React.Fragment>
							}

							{this.state.data.sheet.store != 999999 && this.state.data.sheet.store != 9997 &&
								<React.Fragment>
									<div className={style.label}>ภาค / ปีการศึกษา</div>
									<div className={style.explainLabel}>3 หมายรวมถึงภาคฤดูร้อน</div>
									<select className={style['textFieldHalf']} value={this.state.data.sheet.semester} onChange={(event) => { this.setField('semester', event.currentTarget.value) }} >
										{this.genTermOptions()}
									</select>
									<select className={style['textFieldHalf']} value={this.state.data.sheet.year} onChange={(event) => { this.setField('year', event.currentTarget.value) }} >
										{this.genYearOptions()}
									</select>
								</React.Fragment>
							}

							{this.state.data.sheet.store == 999999 || this.state.data.sheet.store == 9997?
								<React.Fragment>
									<div className={style.label}>ชื่อชีทบรรทัดแรกในภาพหน้าปก</div>
									<div className={style.explainLabel}>ตัวพิมพ์ใหญ่เท่านั้น และห้ามมีเว้นวรรค เช่น BIOLOGY</div>
									<input type='text' className={style['textField']} value={this.state.sCode} onChange={(event) => { this.setFieldCode(event.currentTarget.value) }} />
								</React.Fragment> :
								<React.Fragment>
									<div className={style.label}>รหัสวิชา</div>
									<div className={style.explainLabel}>ตัวพิมพ์ใหญ่เท่านั้น และห้ามมีเว้นวรรค เช่น 2110611 หรือ TU101</div>
									<input type='text' className={style['textField']} value={this.state.sCode} onChange={(event) => { this.setFieldCode(event.currentTarget.value) }} />
								</React.Fragment>
							}

							{this.state.data.sheet.store == 999999 || this.state.data.sheet.store == 9997 ?
								<React.Fragment>
									<div className={style.label}>ชื่อชีทบรรทัดที่สองในภาพหน้าปก</div>
									<div className={style.explainLabel}>เช่น ชีววิทยาทั่วไป</div>
									<input type='text' className={style['textField']} value={this.state.sName} onChange={(event) => { this.setFieldName(event.currentTarget.value) }} />
								</React.Fragment> :
								<React.Fragment>
									<div className={style.label}>ชื่อวิชา รวมข้อมูลที่อยากให้ปรากฎชัดๆบนหน้าปก</div>
									<div className={style.explainLabel}>เช่น การเขียนโปรแกรมเบื้องต้น เซคพุธบ่าย</div>
									<input type='text' className={style['textField']} value={this.state.sName} onChange={(event) => { this.setFieldName(event.currentTarget.value) }} />
								</React.Fragment>
							}

							{(this.state.data.sheet.store != 999999 || this.state.data.sheet.store == 9997) &&
								<React.Fragment>
									<div className={style.label}>รายละเอียดคลาส / ตอนเรียน</div>
									<div className={style.explainLabel}>รูปแบบที่แนะนำ : หมายเลขตอนเรียน+ชื่ออาจารย์ เช่น Sec33 อาจารย์สมชาย</div>
									<textarea className={style['textArea']} value={this.state.data.sheet.classInfo} onChange={(event) => { this.setField('classInfo', event.currentTarget.value) }} />
								</React.Fragment>
							}

							<div className={style.label}>รายละเอียดเนื้อหา</div>
							<div className={style.explainLabel}>เช่น มีเนื้อหาบทที่ 1 และ 2 แต่ไม่รวมบท 3 และ 4</div>
							<textarea className={style['textArea']} value={this.state.data.sheet.detail} onChange={(event) => { this.setField('detail', event.currentTarget.value) }} />

							<div className={style.label}>ภาพหน้าปก</div>
							<div className={style.explainLabel}></div>
							{this.state.updCover ?
								<div className={style.uploadInfo}>กำลังอัพโหลด</div>
								:
								<React.Fragment>
									<div className={style.explainLabel}>รูปที่ใช้อยู่(สร้างอัตโนมัติเมื่อมีการเซฟ) :</div>
									{
										this.state.cover ?
											<a href={this.state.cover} target='_blank' rel='noopener noreferrer'><img alt='' src={this.state.cover} className={style['coverImage']} /></a>
											:
											<div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
									}
								</React.Fragment>
							}

							<div className={style.label}>เปิดให้ซื้อ</div>
							<div className={style.explainLabel}>หากปิดคนซื้อจะไม่สามารถ Search เพื่อซื้อได้</div>
							<select className={style['textField']} value={this.state.data.sheet.visible} onChange={(event) => { this.setField('visible', event.currentTarget.value) }} >
								<option value={true} label='เปิด'>เปิด</option>
								<option value={false} label='ปิด'>ปิด</option>
							</select>
							{this.state.saving ? <div className={style.smallLoader} /> : <div onClick={() => this.saveDraft()} className={style.sendButton}>บันทึกการเปลี่ยนแปลง</div>}
							<hr />
							<div className={style.redText}>หากต้องการแก้ไขข้อมูลอื่นๆ เช่น ไฟล์ ราคา จะต้องทำการส่งตรวจสอบอีกครั้ง โดยระหว่างนั้น ชีทจะถูกนำออกจากหน้าวางขายไป การกดปุ่มด้านล่าง จะทำให้ชีทของท่านกลับสู่สถานะ "ร่าง" โดยหากท่านไม่ส่งตรวจสอบอีกรอบ ชีทของท่านจะไม่ถูกวางขาย และในการแก้ไข โปรดใส่ข้อความในกล่องข้อความ เพื่อบอกทีมงานว่าได้แก้ไขอะไรไปบ้าง</div>
							<div onClick={this.confirmSend} className={style.hardEditButton}>ฉันเข้าใจ และต้องการนำไฟล์กลับสู่สถานะ "ร่าง"</div>
						</div>
					</Container>
				</div>
				<Footer />
			</React.Fragment>
		);
	}

	genUniOptions = () => {
		if (this.state.data.store.length === 0)
			return [{ value: '0', name: '' }];
		return this.state.data.store.map(({ name, short, code }) => (
			{ value: code.toString(), name: name[0] }
		)
		);
	}

	setUni = async (value) => {
		var dat = this.state.data;
		dat.sheet.store = parseInt(value.value);
		this.setState({ data: dat });
	}

	genCatOptions = () => {
		const ops = [{ label: 'ไฟนอล', value: 2 }, { label: 'มิดเทอม', value: 1 }, { label: 'อื่น ๆ', value: 0 }];
		return ops.map(({ label, value }) => (
			<option label={label} value={value} key={value}>{label}</option>
		)
		);
	}

	genTermOptions = () => {
		const ops = [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }];
		return ops.map(({ label, value }) => (
			<option label={label} value={value} key={value}>{label}</option>
		)
		);
	}

	genYearOptions = () => {
		var ops = [];
		var curr = new Date().getFullYear();
		for (var i = 0; i < 10; i++)
			ops.push(curr - i);
		return ops.map((y) => (
			<option label={y} value={y} key={y}>{y}</option>
		)
		);
	}

	setField = async (field, value) => {
		var dat = this.state.data;
		dat.sheet[field] = value;
		this.setState({ data: dat });
	}

	setFieldCode = async (value) => {
		this.setState({ sCode: value.replace(/\s/g, "").toUpperCase() });
	}

	setFieldName = async (value) => {
		this.setState({ sName: value });
	}

	reloadCover = async (id) => {
		var cover = await Util.checkCover(id);
		await this.setState({ cover: cover });
	}

	saveDraft = async () => {
		if (this.state.saving)
			return;
		await this.setState({ saving: true });
		var clonedObj = Object.assign({}, this.state.data.sheet);
		clonedObj.name = this.state.sCode.trim() + ' ' + this.state.sName.trim();
		clonedObj.sCode = this.state.sCode.trim();
		clonedObj.sName = this.state.sName.trim();
		clonedObj.visible = (clonedObj.visible != 'false');
		var data = await Util.sendPost('/api/seller/saveEdit', clonedObj);
		console.log(clonedObj);
		if (data.success) {
			this.props.alert.show('แก้ไขแล้ว', { type: 'success' });
			await Util.sleep(1000);
			this.props.history.push('/seller/mysheet');
		}
	}

	confirmSend = async (even) => {
		var res = window.confirm('ยืนยันว่าคุณจะนำชีทออกจากการขายชั่วคราว?');
		if (res) {
			var data = await Util.sendPost('/api/seller/confirmGoDraft', { id: this.state.data.sheet.id });
			if (data.success) {
				this.props.alert.show('ปรับสถานะแล้ว', { type: 'success' });
				await Util.sleep(1000);
				this.props.history.push('/seller/editSheet?' + this.state.data.sheet.id);
			}
		}
	}

	nl2br = (text) => {
		return text.split('\n').map((item, key) => {
			return (
				<span className={style.line} key={key}>
					{item}
				</span>)
		})
	}

}

export default withRouter(withAlert()(EditSheet));