import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import SellSheet from '../../Components/SellSheet';
import style from './Style.module.css';
import { Link, Redirect } from 'react-router-dom';
import { Container, Visible, Hidden } from 'react-grid-system';
import Footer from '../../Components/Footer';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			sheetID: '',
			data: {
				user: {
					infoEnter: true,
				},
				report: [],
				sellerData: {

				},
			},
			reportBox: {
				show:false,
				message: '',
				id:'',
				type: 0 // 0 for reporter, 1 for all buyer
			},
			dealtFilter: false,
		};
	}

	async componentDidMount() {
		var data = await Util.sendPost('/api/seller/getSellReport', {});
		if (data.success)
			await this.setState({ data: data.data });
		else
			this.props.history.push('/seller');
	}

	render() {
		return (
			<React.Fragment>
				<div className={style.shopPage}>
					<Nav current='seller' user={this.state.data.user} />
					<Container>
						<div className={style.blanker} />
						<div className={style.bigHeader}>Report จากผู้ซื้อ</div>
						<div className={style.sellID}>{'รหัสผู้ขาย : ' + this.state.data.user._id}</div>
						<hr />
						<div className={style.filterButtonGroup}>
							<div className={this.state.dealtFilter?style.filterButton:style.filterButton+" "+style.active} onClick={()=>this.setState({dealtFilter:false})}>ยังไม่จัดการ ({this.countNonDealt()})</div>
							<div className={this.state.dealtFilter?style.filterButton + " " + style.right + " " + style.active:style.filterButton + " " + style.right} onClick={()=>this.setState({dealtFilter:true})}>จัดการแล้ว ({this.countDealt()})</div>
						</div>
						<div className={style.payContainer}>
						{this.renderRep()}
						</div>
					</Container>
				</div>
				<Footer />
				{this.state.reportBox.show &&
					<div className={style.reportBox}>
						<div className={style.rClose} onClick={() => this.setRep("show", false)}> X </div>
						<div className={style.rHeader}>{this.state.reportBox.type===0?"ข้อความถึงคน Report":"ข้อความถึงผู้ซื้อทุกคน"}</div>
						<hr />
						<div className={style.rLabel}>{"ข้อความ(ไม่เกิน 200 ตัวอักษร)"}</div>
						<textarea onChange={(event) => this.setRep('message', event.currentTarget.value)} className={style.tAreaLong} value={this.state.reportBox.message} />
						<div className={style.bGroup}>
							<div className={style.rAddRed} onClick={this.submitMessage}>ส่งข้อความ</div>
						</div>
					</div>
				}
			</React.Fragment>
		);
	}

	renderRep = () => {
		var base = this.state.data.report.filter(data => data.dealt===this.state.dealtFilter);
		return base.map((data) => {
			return (<div key={data._id}><div className={style.payBox}>
				<div className={style.payHead}>{'วันที่ ' + data.created.substring(0, 10)}</div>
				<hr />
				<Link to={"/infoSheet?" + data.sheet} target="_blank" rel="noreferer noopenner"><img alt="sheet" className={style.payMoneyImage} src={process.env.REACT_APP_SHEET_URL+data.sheet+'/cover.jpg'} /></Link>
				<div className={style.paySubHead}>
					เหตุผลหลัก:
					<div className={style.paySubText}>{data.category}</div>
				</div>
				<div className={style.paySubHead}>ข้อความเพิ่มเติม</div>
				<div className={style.payMessage}>
					{this.nl2br(data.message)}
				</div> 
				{data.dealt?<div className={style.greenText}>จัดการแล้ว</div>:
				<React.Fragment>
					<div className={style.yellowButton} onClick={()=>this.sendMessage0(data._id)}>ส่งข้อความหาคน Report</div>
					<div className={style.yellowButton} onClick={()=>this.sendMessage1(data._id)}>ส่งข้อความหาทุกคนที่ซื้อ Sheet</div>
					<div className={style.greenButton} onClick={()=>this.submitMark(data._id)}>Mark ว่าจัดการแล้ว</div>
				</React.Fragment>
				}
			</div></div >)
		}
		);
	}

	countDealt = () => {
		const result = this.state.data.report.filter(data => data.dealt);
		return result.length;
	}

	countNonDealt = () => {
		const result = this.state.data.report.filter(data => !data.dealt);
		return result.length;
	}

	submitMessage = async () => {
		var data = await Util.sendPost('/api/seller/submitReportMessage', this.state.reportBox);
		if (!data.success)
			alert(data.msg[0]);
		else {
			if(this.state.reportBox.type===0)
				alert('ข้อความถูกส่งให้ผู้ Report แล้ว');
			else
				alert('ข้อความถูกส่งให้ผู้ซื้อทุกคนแล้ว');
			this.setState({reportBox: {show:false, message: '', type: 0, id: "" } });
		}
	}

	sendMessage0 = async (id) => {
		this.setState({reportBox: {show:true, message: '', type: 0, id: id } });
	}

	sendMessage1 = async (id) => {
		this.setState({reportBox: {show:true, message: '', type: 1, id: id } });
	}

	submitMark = async (id) => {
		var conf = window.confirm("ยืนยันว่าต้องการ Mark Report นี้ว่าจัดการแล้ว");
		if(!conf)
			return;
		var data = await Util.sendPost('/api/seller/submitReportMark', {id: id});
		if (!data.success)
			alert(data.msg[0]);
		else {
			alert("Mark สำเร็จ");
			data = await Util.sendPost('/api/seller/getSellReport', {});
			if (data.success)
				await this.setState({ data: data.data });
		}
	}

	nl2br = (text) => {
		return text.split('\n').map((item, key) => {
			return <React.Fragment key={key}>{item}<br /></React.Fragment>
		});
	}

	setRep = (field, value) => {
		var x = this.state.reportBox;
		x[field] = value;
		this.setState({ report: x });
	}
}

export default withRouter(withAlert()(Sell));