import React from 'react';
import style from './Style.module.css';
import Util from '../../Util';
import { Link } from 'react-router-dom';
import {Hidden, Visible} from 'react-grid-system';
import star from '../../asset/star.png';

class SellSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<React.Fragment>
				<Hidden xs>
					<div className={style.wrapper}>
						<img onClick={this.navigatora} className={style.cover} src={process.env.REACT_APP_GROUP_URL+this.props.group._id+'/cover.jpg'} alt='cover'></img>
						<div onClick={this.navigatora} className={style.addCart}>รายละเอียดเพิ่มเติม</div>
						<div className={style.addCart} onClick={(event)=>this.props.addCart(this.props.group)}>เพิ่มทั้งหมดลงตะกร้า</div>
					</div>
				</Hidden>
				<Visible xs>
					<div className={style.wrapperMobile}>
						<img onClick={this.navigatora} className={style.coverMobile} src={process.env.REACT_APP_GROUP_URL+this.props.group._id+'/cover.jpg'} alt='cover'></img>
						<div>
							<div onClick={this.navigatora} className={style.infoMobile} >รายละเอียดเพิ่มเติม</div>
							<div className={style.addCartMobile} onClick={(event)=>this.props.addCart(this.props.group)}>เพิ่มทั้งหมดลงตะกร้า</div>
						</div>
					</div>
				</Visible>
			</React.Fragment>
		);
	}
	
	navigatora = (event) => {
		window.open('/infoGroup?'+this.props.group._id,'_blank');
	}
}

export default SellSheet;