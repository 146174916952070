import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import FavList from '../../Components/FavList';
import Footer from '../../Components/Footer';
import MySheet from '../../Components/MySheet';
import style from './Style.module.css';
import { Link , Redirect } from 'react-router-dom';
import {Container,Hidden,Visible} from 'react-grid-system';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import star from '../../asset/star.png';

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			data:{
				user:{
					infoEnter:true,
				},
			},
			showAddFavorite:false,
			aname:'',
			fList:[],
			showEdit:false,
			ename:'',
			eID:''
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/user/getMyFav',{});
		if(data.success)
		{
			await this.setState({data:data.data});
			this.reloadFav();
		}
		else
			this.props.history.push('/');
	}

	render() {
		return (
			<React.Fragment>
				{this.state.showAddFavorite && 
				<div className={style.addFavoriteBox}>
					<div className={style.fClose} onClick={()=>this.setState({showAddFavorite:false})}> X </div>
					<div className={style.fHeader}>เพิ่มรายการโปรดใหม่</div>
					<hr />
					<div className={style.fLabel}>ตั้งชื่อให้รายการของคุณ (เช่น รอซื้อใกล้สอบ)</div>
					<input className={style.addFavText} type='text' value={this.state.aname} onChange={(event)=>this.setState({aname:event.currentTarget.value})} />
					<div className={style.bGroup}>
						<div className={this.state.aname==''?style.fAdd+' '+style.inactive:style.fAdd} onClick={this.state.aname==''?()=>{}:this.addFavList}>เพิ่ม</div>
					</div>
				</div>
				}
				{this.state.showEdit && 
				<div className={style.addFavoriteBox}>
					<div className={style.fClose} onClick={()=>this.setState({showEdit:false})}> X </div>
					<div className={style.fHeader}>แก้ไขชื่อรายการโปรด</div>
					<hr />
					<div className={style.fLabel}>ชื่อใหม่</div>
					<input className={style.addFavText} type='text' value={this.state.ename} onChange={(event)=>this.setState({ename:event.currentTarget.value})} />
					<div className={style.bGroup}>
						<div className={this.state.ename==''?style.fAdd+' '+style.inactive:style.fAdd} onClick={this.state.ename==''?()=>{}:this.editFavList}>แก้</div>
					</div>
				</div>
				}
				<div className={style.shopPage}>
					<Nav current='shop' user={this.state.data.user}/>
					<Container>
						<div className={style.blanker} />
						<div className={style.bigHeader}>รายการโปรดของคุณ</div>
						<hr />
						<div className={style.fAdd} onClick={()=>{this.setState({aname:'',showAddFavorite:true})}}>เพิ่มรายการโปรดใหม่</div>
						{this.renderList()}
					</Container>
				</div>
				<Footer />
			</React.Fragment>
		);
	}
	
	reloadFav = async() => {
		var data = await Util.sendPost('/api/user/getFavoriteList',{});
		if(data.success)
			await this.setState({fList:data.data.list});
		else
			this.props.history.push('/');
	}
	
	addFavList=async()=>{
		var data = await Util.sendPost('/api/user/addFavorite',{name:this.state.aname});
		if(data.success) {
			this.props.alert.show('เพิ่มรายการใหม่สำเร็จ',{type: 'success'});
			await this.setState({showAddFavorite:false,aname:''});
			this.reloadFav();
		}
		else {
			this.props.alert.show(data.msg[0],{type: 'error'});
		}
	}
	
	editFavList=async()=>{
		var data = await Util.sendPost('/api/user/renameFavorite',{id:this.state.eID,name:this.state.ename});
		if(data.success) {
			this.props.alert.show('แก้ไขสำเร็จ',{type: 'success'});
			await this.setState({showEdit:false,ename:'',eID:''});
			this.reloadFav();
		}
		else {
			this.props.alert.show(data.msg[0],{type: 'error'});
		}
	}
	
	editFav=async(id,name)=>{
		this.setState({showEdit:true,eID:id,ename:name});
	}
	
	renderList =()=>{
		if(this.state.fList.length==0) {
			return (<div className={style.nothing}>ไม่พบรายการที่เคยสร้างไว้</div>)
		}
		return this.state.fList.map((list)=>
			(<FavList key ={list._id} list={list} reload={this.reloadFav} edit={this.editFav} />));
	}
}

export default withRouter(withAlert()(Sell));