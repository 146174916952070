import React from 'react';
import style from './Style.module.css';
import Util from '../../Util';
import text from './text';
import logo from '../../asset/fb.png';


class FacebookLogin extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		const lang = Util.getLang();
		return (
			<div onClick= {()=>{this.props.loginFacebook()}} className={style.goldButton}>
				<img alt='' src={logo} className={style.fLogo} />
			</div>
		);
	}
}

export default FacebookLogin;