import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import MySheet from '../../Components/MySheet';
import style from './Style.module.css';
import { Link, Redirect } from 'react-router-dom';
import { Container, Hidden, Visible } from 'react-grid-system';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import star from '../../asset/star.png';

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: {
				user: {
					infoEnter: true,
				},
				store: [],
			},
			sheets: {
				verf: [],
				unverf: [],
			},
			verfFil: [],
			filter: {
				search: '',
				sem: 'any',
				year: '0',
			},
			storeMap: {

			},
			showReview: false,
			reviewID: '',
			reviewName: '',
			review: {
				before: false,
				score: 0,
				message: '',
			},
			showReport: false,
			reportID: '',
			reportName: '',
			report: {
				category: 'ชีทอ่านไม่รู้เรื่อง',
				message: '',
			}
		};
	}

	async componentDidMount() {
		var data = await Util.sendPost('/api/user/getMySheet', {});
		if (data.success) {
			await this.setState({ data: data.data });
			var map = {};
			for (var i = 0; i < data.data.store.length; i++)
				map[data.data.store[i].code] = { color: data.data.store[i].color, short: data.data.store[i].short };
			await this.setState({ storeMap: map });
			this.reloadSheets();
		}
		else
			this.props.history.push('/');
	}

	render() {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				<div className={style.shopPage}>
					{this.state.showReview &&
						<div className={this.state.review.before ? style.reviewBox : style.reviewBoxShort}>
							<div className={style.rClose} onClick={() => this.setState({ showReview: false })}> X </div>
							<div className={style.rHeader}>Comment</div>
							<div className={style.rsn}>ชีท: {this.state.reviewName}</div>
							<hr />
							<div className={style.rLabel}>ข้อความติชม (ไม่เกิน 100 ตัวอักษร)</div>
							<textarea onChange={(event) => this.setRev('message', event.currentTarget.value)} className={style.tArea} value={this.state.review.message} />
							{this.state.review.before ?
								<div className={style.bGroup}>
									<div className={style.rAdd} onClick={this.submitReview}>บันทึก</div>
									<div className={style.rErase} onClick={this.deleteReview}>ลบ Comment</div>
								</div> :
								<div className={style.bGroup}>
									<div className={style.rAdd} onClick={this.submitReview}>เพิ่ม Comment</div>
								</div>
							}
						</div>
					}
					{this.state.showReport &&
						<div className={style.reportBox}>
							<div className={style.rClose} onClick={() => this.setState({ showReport: false })}> X </div>
							<div className={style.rHeader}>Report</div>
							<div className={style.rsn}>ชีท: {this.state.reportName}</div>
							<hr />
							<div className={style.rLabel}>เหตุผล</div>
							<select onChange={(event) => this.setRep('category', event.currentTarget.value)} className={style.tSelect} value={this.state.report.category}>
								<option value="ชีทอ่านไม่รู้เรื่อง" label="ชีทอ่านไม่รู้เรื่อง">ชีทอ่านไม่รู้เรื่อง</option>
								<option value="ชีทพื้นที่ว่างเยอะเกินไป" label="ชีทพื้นที่ว่างเยอะเกินไป">ชีทพื้นที่ว่างเยอะเกินไป</option>
								<option value="ชีทเนื้อหาไม่ครบถ้วน" label="ชีทเนื้อหาไม่ครบถ้วน">ชีทเนื้อหาไม่ครบถ้วน</option>
								<option value="ชีทมีการละเมิดลิขสิทธิ์" label="ชีทมีการละเมิดลิขสิทธิ์">ชีทมีการละเมิดลิขสิทธิ์</option>
							</select>
							<hr />
							<div className={style.rLabel}>ข้อความเพิ่มเติม (ไม่เกิน 200 ตัวอักษร)</div>
							<textarea onChange={(event) => this.setRep('message', event.currentTarget.value)} className={style.tAreaLong} value={this.state.report.message} />
							<div className={style.bGroup}>
								<div className={style.rAddRed} onClick={this.submitReport}>ส่ง Report</div>
							</div>
						</div>
					}
					<Nav current='shop' user={this.state.data.user} />
					<Container>
						<div className={style.blanker} />
						<div className={style.bigHeader}>ชีทที่ซื้อไว้</div>
						<hr />
						<div className={style.reject}>
							<div className={style.rejectHeader}>ประกาศ</div>
							<div className={style.rejectReason}>
								ห้ามส่งต่อหรือถ่ายเอกสารชีทสรุป เพื่อเป็นการให้เกียรติแก่ผู้เขียน
								</div>
						</div>

						<Visible xs sm>
							<div className={style.barMobile + ' ' + style.searcher}>
								<input type='text' placeholder={'ค้นหาตามชื่อ'} className={style.search + ' ' + style.mobile} value={this.state.filter.search} onChange={(event) => this.setFilter('search', event.currentTarget.value)} />
							</div>
							<div className={style.barMobile + ' ' + style.filter}>
								<select className={style.semSelector + ' ' + style.mobile} value={this.state.filter.sem} onChange={(event) => this.setFilter('sem', event.currentTarget.value)}>{this.genTermOptions(lang)}</select>
								<select className={style.semSelector + ' ' + style.mobile + ' ' + style.last} value={this.state.filter.year} onChange={(event) => this.setFilter('year', event.currentTarget.value)}>{this.genYearOptions(lang)}</select>
							</div>
						</Visible>
						<Hidden xs sm>
							<div className={style.filterBar}>
								<input type='text' placeholder={'ค้นหาตามชื่อ'} className={style.search} value={this.state.filter.search} onChange={(event) => this.setFilter('search', event.currentTarget.value)} />
								<select className={style.semSelector} value={this.state.filter.sem} onChange={(event) => this.setFilter('sem', event.currentTarget.value)}>{this.genTermOptions(lang)}</select>
								<select className={style.semSelector} value={this.state.filter.year} onChange={(event) => this.setFilter('year', event.currentTarget.value)}>{this.genYearOptions(lang)}</select>
							</div>
						</Hidden>
						<div className={style.sheetWrapper}>
							{this.renderVerf()}
						</div>
					</Container>
				</div>
				<Footer />
			</React.Fragment>
		);
	}

	setRev = (field, value) => {
		var x = this.state.review;
		x[field] = value;
		this.setState({ review: x });
	}

	setRep = (field, value) => {
		var x = this.state.report;
		x[field] = value;
		this.setState({ report: x });
	}

	submitReview = async () => {
		var id = this.state.reviewID;
		var data = await Util.sendPost('/api/user/addMyScore', { sheet: id, score: this.state.review.score, message: this.state.review.message });
		if (!data.success)
			alert(data.msg[0]);
		else {
			alert('Comment ถูกเพิ่ม/แก้ไขสำเร็จ');
			this.setState({ reviewID: '', reviewName: '', showReview: false, review: { before: false, message: '', score: 0 } })
		}
	}
	submitReport = async () => {
		var id = this.state.reportID;
		var data = await Util.sendPost('/api/user/addMyReport', { sheet: id, message: this.state.report.message, category: this.state.report.category });
		if (!data.success)
			alert(data.msg[0]);
		else {
			alert('Report ของคุณถูกส่งให้ผู้เขียนชีทแล้ว หากมีการเปลี่ยนแปลงจากทางผู้เขียนจะมีการแจ้งเตือนมาถึงคุณ');
			this.setState({ reportID: '', reportName: '', showReport: false, report: { message: '' } })
		}
	}
	deleteReview = async () => {
		var id = this.state.reviewID;
		var data = await Util.sendPost('/api/user/deleteMyScore', { sheet: id });
		if (!data.success)
			alert(data.msg[0]);
		else {
			alert('Comment ถูกลบสำเร็จ');
			this.setState({ reviewID: '', reviewName: '', showReview: false, review: { before: false, message: '', score: 0 } })
		}
	}

	reloadSheets = async () => {
		var data = await Util.sendPost('/api/user/getMyallSheet', {});
		var allSheets = { verf: [] };
		for (var i = 0; i < data.data.sheets.length; i++) {
			var sheet = data.data.sheets[i];
			var realsheet = sheet.sheet;
			if (realsheet) {
				realsheet.created = sheet.created;
				allSheets.verf.push(realsheet);
			}
		}
		await this.setState({ sheets: allSheets });
		await this.goFilter();
	}

	renderVerf = () => {
		const lang = Util.getLang();
		if (this.state.verfFil.length === 0)
			return (<div className={style.nothing}>ไม่พบรายการ</div>);
		return this.state.verfFil.map((sheet) => {
			return (<MySheet reviewHandler={this.review} reportHandler={this.report} sheet={sheet} key={sheet._id} />)
		}
		);
	}

	review = async (sheet) => {
		var id = sheet._id;
		var data = await Util.sendPost('/api/user/getMyScore', { sheet: id });
		if (!data.success)
			alert('Error, please try again');
		else {
			if (data.data.score) {
				this.setState({ reviewID: id, reviewName: sheet.name, showReview: true, review: { before: true, message: data.data.score.message } });
			}
			else {
				this.setState({ reviewID: id, reviewName: sheet.name, showReview: true, review: { before: false, message: '', score: 0 } })
			}
		}
	}

	report = async (sheet) => {
		var id = sheet._id;
		this.setState({ reportID: id, reportName: sheet.name, showReport: true, report: { message: '', category: 'ชีทอ่านไม่รู้เรื่อง' } });
	}

	genTermOptions = () => {
		const ops = [{ label: 'any', value: 'any' }, { label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }];
		return ops.map(({ label, value }) => (
			<option label={label === 'any' ? 'ทุกเทอม' : label} value={value} key={value}>{label === 'any' ? 'ทุกเทอม' : label}</option>
		)
		);
	}

	genYearOptions = () => {
		var ops = [0];
		var curr = new Date().getFullYear();
		for (var i = 0; i < 10; i++)
			ops.push(curr - i);
		return ops.map((y) => (
			<option label={y === 0 ? 'ทุกปี' : y} value={y} key={y}>{y === 0 ? ('ทุกปี') : y}</option>
		)
		);
	}

	setFilter = async (field, val) => {
		var x = this.state.filter;
		x[field] = val;
		await this.setState({ filter: x });
		await this.goFilter();
	}

	goFilter = async () => {
		var res = [];
		var hi = this.state.filter.search.trim().toLowerCase();
		for (var i = 0; i < this.state.sheets.verf.length; i++) {
			var sheet = this.state.sheets.verf[i];
			if (this.state.filter.sem !== 'any' && parseInt(this.state.filter.sem) !== sheet.semester)
				continue;
			if (this.state.filter.year !== '0' && parseInt(this.state.filter.year) !== sheet.year)
				continue;
			if (this.state.filter.search.trim() !== '' && !sheet.name.toLowerCase().includes(hi))
				continue;
			sheet.created = (new Date(sheet.created)).getTime();
			res.push(sheet);
		}
		var sorter = {
			date: function (a, b) {
				return b.created - a.created;
			}
		};
		res.sort(sorter.date);
		await this.setState({ verfFil: res });
	}
}

export default withRouter(withAlert()(Sell));