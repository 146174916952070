import React from 'react';
import Util from '../../Util';
import style from './Style.module.css';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
//import './style.css';
const monthName = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			out: [],
			should: false,
			month: parseInt(props.location.search.substring(1).split('&')[0]),
			year: parseInt(props.location.search.substring(1).split('&')[1])
		};
	}

	async componentDidMount() {
		var data = await Util.sendPost('/api/seller/getslip', { month: this.state.month, year: this.state.year });
		if (data.success)
			await this.setState({ out: data.data.yours, should: data.data.started });
		else
			this.props.history.push('/seller');
	}

	render() {
		return (
			<div className={style.page}>
				<div className={style.header}>
					ข้อมูลจากธนาคารไทยพาณิชย์ ล่าสุด {(new Date()).toLocaleDateString("th-TH", options)}
				</div>
				<div className={style.slipWrapper}>
					{this.state.should ? <React.Fragment>
						{this.state.out.length == 0 && <div className={style.danger}>การโอนเงินมีปัญหา โปรดติดต่อ Facebook Page: more sheet</div>}
						{this.state.out.length > 0 && this.renderSlip()}
					</React.Fragment> : <React.Fragment>
							<div className={style.warning}>กำลังอยู่ในขั้นตอนการโอนเงิน</div>
						</React.Fragment>}
				</div>
			</div>
		);
	}

	renderSlip = () => {
		return this.state.out.map((data) => {
			return (<div key={data._id} className={style.slipBox}>
				<div className={style.name}>{data.name}</div>
				<div className={style.amount}>{data.amount.toFixed(2).toString()} THB</div>
				<div className={style.date}>{data.date}</div>
				<div className={style.success}>SUCCESS</div>
				<div className={style.success2}>TRANSACTION IS SUCCESSFUL (00)</div>
				<div className={style.success3}>PO. PUN.</div>
				<div className={style.behead}>Beneficiary data</div>
				<div className={style.besubhead}>Beneficiary name</div>
				<div className={style.bedata}>{data.name}</div>
				<div className={style.besubhead}>Beneficiary account number</div>
				<div className={style.bedata}>{data.accountNumber}</div>
				<div className={style.behead}>Company data</div>
				<div className={style.besubhead}>Company name</div>
				<div className={style.bedata}>ห้างหุ้นส่วนจำกัด มอร์ เซอร์วิสเซส</div>
				<div className={style.besubhead}>Company account number</div>
				<div className={style.bedata}>2182757533</div>
				<div className={style.behead}>Payment details</div>
				<div className={style.besubhead}>Fee amount</div>
				<div className={style.bedata}>0.00 THB</div>
				<div className={style.besubhead}>Charges on</div>
				<div className={style.bedata}>Sender</div>
			</div>)
		});
	}

}

export default withRouter(withAlert()(EditSheet));