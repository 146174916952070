import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import Util from '../../Util';
import style from './Style.module.css';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-grid-system';

class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			redirect: false,
			data: {
				sheet: {
					name: '',
					classInfo: '',
					detail: '',
					price: '',
					examNum: 0,
					id: '',
					semester: 1,
					year: 2019,
					category: 0,
					visible: true,
					highlighted: false,
				},
			},
			sCode: '',
			sName: '',
			cover: false,
			example: false,
			pdf: false,
			updCover: false,
			updSample: false,
			updPdf: false,
			saving: false,
			showSplit: false,
			splitname: '',
			splitStep: 0,
			pages: [{ start: 1, end: 3, cover: false }],
			newpages: [],
		};
	}

	async componentDidMount() {
		var id = window.location.search.substring(1);
		var data = await Util.sendPost('/api/moresheetStaff/getSheet', { id });
		if (!data.success) {
			await alert(data.msg[0]);
			return this.props.history.push('/staff/sheetInfo');
		}
		var name = data.data.sheet.name;
		await this.setState({ sCode: name.substr(0, name.indexOf(' ')), sName: name.substr(name.indexOf(' ') + 1) });
		await this.setState({ data: data.data });
		await this.reloadCover(id);
		await this.loadSamples();
		await this.loadPdf();
	}

	render() {
		const lang = Util.getLang();
		return (
			<React.Fragment>
				<div className={style.sheetPage}>
					<Container>
						<div className={style.container}>
							<div className={style.blankTop} />
							<div className={style.header}>Staff แก้ไขชีท</div>
							<hr />
							{this.state.data.sheet.visible ?
								<React.Fragment>
									<div className={style.statusGreen}>ชีทเปิดให้ซื้อได้ในขณะนี้</div>
									<div className={style.hideButt} onClick={this.hideSheet}>ซ่อนชีท</div>
								</React.Fragment> :
								<React.Fragment>
									<div className={style.statusRed}>ชีทถูกซ่อน</div>
									<div className={style.showButt} onClick={this.showSheet}>เปิดชีทให้ซื้อ</div>
								</React.Fragment>
							}

							{this.state.data.sheet.highlighted ?
								<React.Fragment>
									<div className={style.statusGreen}>ชีทถูกแนะนำ</div>
									<div className={style.hideButt} onClick={this.stopHighlightSheet}>เอาชีทออกจากการแนะนำ</div>
								</React.Fragment> :
								<React.Fragment>
									<div className={style.statusRed}>ชีทไม่ถูกแนะนำ</div>
									<div className={style.showButt} onClick={this.highlightSheet}>ตั้งเป็นชีทแนะนำ</div>
								</React.Fragment>
							}

							<div className={style.label}>ชนิดของชีทสรุป</div>
							<select className={style['textField']} value={this.state.data.sheet.category} onChange={(event) => { this.setField('category', event.currentTarget.value) }} >
								{this.genCatOptions()}
							</select>

							<div className={style.label}>ภาค / ปีการศึกษา</div>
							<select className={style['textFieldHalf']} value={this.state.data.sheet.semester} onChange={(event) => { this.setField('semester', event.currentTarget.value) }} >
								{this.genTermOptions()}
							</select>
							<select className={style['textFieldHalf']} value={this.state.data.sheet.year} onChange={(event) => { this.setField('year', event.currentTarget.value) }} >
								{this.genYearOptions()}
							</select>

							<div className={style.label}>รหัสวิชา</div>
							<div className={style.explainLabel}>ตัวพิมพ์ใหญ่เท่านั้น และห้ามมีเว้นวรรค เช่น 2110611 หรือ TU101</div>
							<input type='text' className={style['textField']} value={this.state.sCode} onChange={(event) => { this.setFieldCode(event.currentTarget.value) }} />

							<div className={style.label}>ชื่อวิชา</div>
							<input type='text' className={style['textField']} value={this.state.sName} onChange={(event) => { this.setFieldName(event.currentTarget.value) }} />

							<div className={style.label}>รายละเอียดคลาส / ตอนเรียน</div>
							<textarea className={style['textArea']} value={this.state.data.sheet.classInfo} onChange={(event) => { this.setField('classInfo', event.currentTarget.value) }} />

							<div className={style.label}>รายละเอียดเนื้อหา</div>
							<textarea className={style['textArea']} value={this.state.data.sheet.detail} onChange={(event) => { this.setField('detail', event.currentTarget.value) }} />

							<div className={style.label}>ราคา</div>
							<input type='text' className={style['textField']} value={this.state.data.sheet.price} onChange={(event) => { this.setField('price', event.currentTarget.value) }} />

							<div className={style.label}>ภาพหน้าปก (หากอัพผิดให้กดเซฟ มันจะสร้างรูปใหม่ ดังนั้นถ้าจะแก้ทั้งข้อมูลทั้งปก เซฟปกเก่าไว้ก่อน)</div>
							<div className={style.explainLabel}></div>
							{this.state.updCover ?
								<div className={style.uploadInfo}>กำลังอัพโหลด</div>
								:
								<React.Fragment>
									<input type='file' className={style['fileField']} onChange={(event) => { this.uploadCover(event.currentTarget.files[0]) }} />
									<div className={style.explainLabel}>รูปที่ใช้อยู่(สร้างอัตโนมัติเมื่อมีการเซฟ) :</div>
									{
										this.state.cover ?
											<a href={this.state.cover} target='_blank' rel='noopener noreferrer'><img alt='' src={this.state.cover} className={style['coverImage']} /></a>
											:
											<div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
									}
								</React.Fragment>
							}

							<div className={style.label}>รูปตัวอย่างชีท (ถ้าอัพแล้วของเก่าจะหาย ห้ามกดปุ่มหากไม่จำเป็น)</div>
							<div className={style.explainLabel}>ตั้งแต่ 1-10 รูป แต่ละรูปขนาดไม่เกิน 1MB (ให้นำรูปมาไว้ในโฟลเดอร์เดียวกันแล้วเลือกทีเดียว)</div>
							{this.state.updSample ?
								<div className={style.uploadInfo}>{this.state.updSample}</div>
								:
								<React.Fragment>
									<input type='file' className={style['fileField']} onChange={(event) => { this.uploadSample(event.currentTarget.files) }} multiple />
									<div className={style.explainLabel}>รูปตัวอย่างที่ใช้อยู่ทั้งหมด :</div>
									{
										this.state.data.sheet.examNum > 0 ?
											this.renderSamples(this.state.example)
											:
											<div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
									}
								</React.Fragment>
							}

							<div className={style.label}>ไฟล์ชีท **ที่ใส่หน้าปกมาแล้ว หาวิธีใส่ได้ในหน้าก่อนหน้า** (ถ้าอัพแล้วของเก่าจะหาย ห้ามกดปุ่มหากไม่จำเป็น)</div>
							<div className={style.explainLabel}>ไฟล์ pdf ขนาดไม่เกิน 150MB</div>
							{this.state.updPdf ?
								<div className={style.uploadInfo}>{this.state.updPdf}</div>
								:
								<React.Fragment>
									<input type='file' className={style['fileField']} onChange={(event) => { this.uploadPdf(event.currentTarget.files[0]) }} />
									<div className={style.explainLabel}>ไฟล์ชีทปัจจุบัน :</div>
									{
										this.state.pdf ?
											<a href={this.state.pdf} target='_blank' rel='noopener noreferrer' className={style.pdfLink}>คลิกเพื่อดูไฟล์</a>
											:
											<div className={style.notFound}>ไม่พบไฟล์ที่เคยอัพโหลด</div>
									}
								</React.Fragment>
							}
							<hr />
							<div className={style.buttContainer}>
								{this.state.saving ?
									<div className={style.smallLoader} />
									:
									<React.Fragment>
										<div onClick={() => this.saveDraft()} className={style.saveButton}>บันทึกการเปลี่ยนแปลง</div>
									</React.Fragment>
								}
							</div>
							<div className={style.buttContainer}>
								<React.Fragment>
									<div onClick={() => this.setState({ showSplit: true })} className={style.warnButton}>แยกบทชีท</div>
								</React.Fragment>
							</div>
						</div>
					</Container>
				</div>
				{this.state.showSplit &&
					<div className={style.addFavoriteBox}>
						<div className={style.fClose} onClick={() => this.setState({ showSplit: false })}> X </div>
						<div className={style.fHeader}>แยกบทชีท</div>
						<hr />
						{this.state.splitStep == 0 &&
							<React.Fragment>
								<div className={style.fLabel}>1.อัพโหลดไฟล์ PDF</div>
								{this.state.updSplit ?
									<div className={style.uploadInfo}>{this.state.updSplit}</div>
									:
									<React.Fragment>
										<input type='file' className={style['fileField']} onChange={(event) => { this.uploadSplit(event.currentTarget.files[0]) }} />
									</React.Fragment>
								}
							</React.Fragment>
						}
						{this.state.splitStep == 1 &&
							<React.Fragment>
								<div className={style.fLabel}>2.แบ่งหน้าของชีท</div>
								{this.renderpagesSelect()}
								<div className={style.bGroup}>
									<div className={style.fPlus} onClick={this.addPage}>+ เพิ่มบท</div>
									<div className={style.fAdd} onClick={this.sendSplit}>ส่งเข้าระบบ</div>
								</div>
							</React.Fragment>
						}
						{this.state.splitStep == 2 &&
							<React.Fragment>
								<div className={style.fLabel}>คลิกที่ชีทด้านล่างเพื่อแก้ไขเพิ่มเติมก่อนเปิดให้คนซื้อ</div>
								{this.renderpagesNewLink()}
							</React.Fragment>
						}
					</div>
				}
			</React.Fragment>
		);
	}


	genUniOptions = () => {
		if (this.state.data.store.length === 0)
			return [{ value: '0', name: '' }];
		return this.state.data.store.map(({ name, short, code }) => (
			{ value: code.toString(), name: name[0] }
		)
		);
	}

	setUni = async (value) => {
		var dat = this.state.data;
		dat.sheet.store = parseInt(value.value);
		this.setState({ data: dat });
	}

	genCatOptions = () => {
		const ops = [{ label: 'ไฟนอล', value: 2 }, { label: 'มิดเทอม', value: 1 }, { label: 'อื่น ๆ', value: 0 }, { label: 'Learning Space', value: 3 }];
		return ops.map(({ label, value }) => (
			<option label={label} value={value} key={value}>{label}</option>
		)
		);
	}

	genTermOptions = () => {
		const ops = [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }];
		return ops.map(({ label, value }) => (
			<option label={label} value={value} key={value}>{label}</option>
		)
		);
	}

	genYearOptions = () => {
		var ops = [];
		var curr = new Date().getFullYear();
		for (var i = 0; i < 10; i++)
			ops.push(curr - i);
		return ops.map((y) => (
			<option label={y} value={y} key={y}>{y}</option>
		)
		);
	}

	setField = async (field, value) => {
		var dat = this.state.data;
		dat.sheet[field] = value;
		this.setState({ data: dat });
	}

	setFieldCode = async (value) => {
		this.setState({ sCode: value.replace(/\s/g, "").toUpperCase() });
	}

	setFieldName = async (value) => {
		this.setState({ sName: value.trim() });
	}

	reloadCover = async (id) => {
		var cover = await Util.checkCover(id);
		await this.setState({ cover: cover });
	}

	loadSamples = () => {
		var urls = [];
		for (var i = 0; i < this.state.data.sheet.examNum; i++)
			urls.push(process.env.REACT_APP_SHEET_URL + this.state.data.sheet.id + '/ex' + (i + 1).toString() + '.jpg?' + Date.now().toString());
		this.setState({ example: urls });
	}

	renderSamples = (urls) => {
		if (!urls)
			return null;
		return urls.map((url) => (
			<a key={url} href={url} target='_blank' rel='noopener noreferrer'><img alt='' src={url} className={style.sampleImage} /></a>
		)
		);
	}

	uploadCover = async (file) => {
		await this.setState({ updCover: true });
		var data = await Util.sendPost('/api/moresheetStaff/uploadCoverUrl', { id: this.state.data.sheet.id });

		var formData = new FormData();
		formData.append('acl', 'public-read');
		var keys = Object.keys(data.data.fields);
		for (var i = 0; i < keys.length; i++)
			formData.append(keys[i], data.data.fields[keys[i]]);
		formData.append('file', file);
		await Util.sendFormData(data.data.url, formData);

		await this.setState({ updCover: false });
		this.reloadCover(this.state.data.sheet.id);
	}

	uploadSample = async (files) => {
		await this.setState({ updSample: ['กำลังขอคิวเพื่ออัพโหลด...', 'Requesting upload queue'] });
		var data = await Util.sendPost('/api/moresheetStaff/uploadExamplesUrl', { id: this.state.data.sheet.id, num: files.length });
		for (var j = 0; j < files.length; j++) {
			await this.setState({ updSample: 'กำลังอัพโหลดรูปที่ ' + (j + 1).toString() + ' จาก ' + files.length.toString() });
			var formData = new FormData();
			formData.append('acl', 'public-read');
			var keys = Object.keys(data.data[j].fields);
			for (var i = 0; i < keys.length; i++)
				formData.append(keys[i], data.data[j].fields[keys[i]]);
			formData.append('file', files[j]);
			await Util.sendFormData(data.data[j].url, formData);
		}
		var d = this.state.data;
		d.sheet.examNum = files.length;
		await this.setState({ updSample: false, data: d });
		this.loadSamples();
	}

	uploadPdf = async (file) => {
		await this.setState({ updPdf: 'เริ่มต้นอัพโหลด' });
		var data = await Util.sendPost('/api/moresheetStaff/uploadPdfUrl', { id: this.state.data.sheet.id });
		var formData = new FormData();
		formData.append('acl', 'private');
		var keys = Object.keys(data.data.fields);
		for (var i = 0; i < keys.length; i++)
			formData.append(keys[i], data.data.fields[keys[i]]);
		formData.append('Content-Type', 'application/pdf');
		formData.append('file', file);
		//xhr
		var xhr = new XMLHttpRequest();
		xhr.open('POST', data.data.url, true);
		xhr.upload.onprogress = async (e) => {
			var percent = Math.ceil((e.loaded / e.total) * 100);
			await this.setState({ updPdf: 'อัพโหลดแล้ว ' + percent.toString() + '%' });
		};
		xhr.onreadystatechange = async () => { // Call a function when the state changes.
			if (xhr.readyState === XMLHttpRequest.DONE) {
				await this.loadPdf();
				await this.setState({ updPdf: false });
			}
		}
		xhr.send(formData);
	}

	uploadSplit = async (file) => {
		await this.setState({ updPdf: 'เริ่มต้นอัพโหลด' });
		var data = await Util.sendPost('/api/moresheetStaff/getsplitsheetlink', {});
		var formData = new FormData();
		await this.setState({ splitname: data.filename })
		formData.append('acl', 'private');
		var keys = Object.keys(data.data.fields);
		for (var i = 0; i < keys.length; i++)
			formData.append(keys[i], data.data.fields[keys[i]]);
		formData.append('Content-Type', 'application/pdf');
		formData.append('file', file);
		//xhr
		var xhr = new XMLHttpRequest();
		xhr.open('POST', data.data.url, true);
		xhr.upload.onprogress = async (e) => {
			var percent = Math.ceil((e.loaded / e.total) * 100);
			await this.setState({ updSplit: 'อัพโหลดแล้ว ' + percent.toString() + '%' });
		};
		xhr.onreadystatechange = async () => { // Call a function when the state changes.
			if (xhr.readyState === XMLHttpRequest.DONE) {
				await this.loadPdf();
				await this.setState({ updSplit: 'สำเร็จ', splitStep: 1 });
			}
		}
		xhr.send(formData);
	}

	loadPdf = async () => {
		var data = await Util.sendPost('/api/moresheetStaff/getSheetPdf', { id: this.state.data.sheet.id });
		await this.setState({ pdf: data.data });
	}

	saveDraft = async () => {
		if (this.state.saving)
			return;
		await this.setState({ saving: true });
		var clonedObj = Object.assign({}, this.state.data.sheet);
		clonedObj.name = this.state.sCode.trim() + ' ' + this.state.sName.trim();
		clonedObj.sCode = this.state.sCode.trim();
		clonedObj.sName = this.state.sName.trim();
		var data = await Util.sendPost('/api/moresheetStaff/saveSheet', clonedObj);
		if (!data.success)
			Util.alertError(data.msg[0]);
		var id = window.location.search.substring(1);
		await this.reloadCover(id);
		await this.setState({ saving: false });
	}

	nl2br = (text) => {
		return text.split('\n').map((item, key) => {
			return (
				<span className={style.line} key={key}>
					{item}
				</span>)
		})
	}

	showSheet = async (event) => {
		await Util.sendPost('/api/moresheetStaff/shsheet', { id: this.state.data.sheet.id, visible: true });
		var id = window.location.search.substring(1);
		var data = await Util.sendPost('/api/moresheetStaff/getSheet', { id });
		await this.setState({ data: data.data });
	}

	hideSheet = async (event) => {
		await Util.sendPost('/api/moresheetStaff/shsheet', { id: this.state.data.sheet.id, visible: false });
		var id = window.location.search.substring(1);
		var data = await Util.sendPost('/api/moresheetStaff/getSheet', { id });
		await this.setState({ data: data.data });
	}

	highlightSheet = async (event) => {
		await Util.sendPost('/api/moresheetStaff/highlightsheet', { id: this.state.data.sheet.id, highlighted: true });
		var id = window.location.search.substring(1);
		var data = await Util.sendPost('/api/moresheetStaff/getSheet', { id });
		await this.setState({ data: data.data });
	}

	stopHighlightSheet = async (event) => {
		await Util.sendPost('/api/moresheetStaff/highlightsheet', { id: this.state.data.sheet.id, highlighted: false });
		var id = window.location.search.substring(1);
		var data = await Util.sendPost('/api/moresheetStaff/getSheet', { id });
		await this.setState({ data: data.data });
	}

	renderpagesSelect = () => {
		return this.state.pages.map((page, ind) => {
			return <div className={style.fLineCon} key={'pageSetting' + ind}>
				<div className={style.fLineLabel}>จากหน้า</div>
				<input type='text' className={style.fLineText} value={page.start} id={'pagesettingStart' + ind} onChange={this.changePageStart} />
				<div className={style.fLineLabel}>ถึงหน้า</div>
				<input type='text' className={style.fLineText} value={page.end} id={'pagesettingEnd' + ind} onChange={this.changePageEnd} />
				<select className={style.fLineSelect} value={page.cover.toString()} id={'pagesettingCover' + ind} onChange={this.changePageCover}>
					<option value='true' label='ใส่หน้าปก'>ใส่หน้าปก</option>
					<option value='false' label='ไม่ใส่หน้าปก'>ไม่ใส่หน้าปก</option>
				</select>
				<div className={style.fLineX} id={'pagesettingDelete' + ind} onClick={this.deletePage}>X</div>
			</div>
		})
	}

	renderpagesNewLink = () => {
		return this.state.newpages.map((page, ind) => {
			return <a target='_blank' rel='noopenner noreferer' href={'/staff/sheetedit?' + page} className={style.linkLineCon} key={'pageLink' + ind}>
				{page}
			</a>
		})
	}

	changePageStart = (event) => {
		var cpy = this.state.pages;
		var id = event.currentTarget.id.replace("pagesettingStart", "");
		cpy[id].start = isNaN(parseInt(event.currentTarget.value)) ? event.currentTarget.value : parseInt(event.currentTarget.value);
		this.setState({ pages: cpy });
	}

	changePageEnd = (event) => {
		var cpy = this.state.pages;
		var id = event.currentTarget.id.replace("pagesettingEnd", "");
		cpy[id].end = isNaN(parseInt(event.currentTarget.value)) ? event.currentTarget.value : parseInt(event.currentTarget.value);
		this.setState({ pages: cpy });
	}

	changePageCover = (event) => {
		var cpy = this.state.pages;
		var id = event.currentTarget.id.replace("pagesettingCover", "");
		cpy[id].cover = event.currentTarget.value == "true";
		this.setState({ pages: cpy });
	}

	addPage = (event) => {
		var cpy = this.state.pages;
		cpy.push({ start: 1, end: 1, cover: false })
		this.setState({ pages: cpy });
	}

	deletePage = (event) => {
		var cpy = this.state.pages;
		var id = event.currentTarget.id.replace("pagesettingDelete", "");
		cpy.splice(id, 1);
		this.setState({ pages: cpy });
	}

	sendSplit = async () => {
		var id = this.state.data.sheet.id;
		var filename = this.state.splitname;
		var pages = this.state.pages;
		var data = await Util.sendPost('/api/moresheetStaff/splitsheet', { id, filename, pages });
		if (!data.success)
			alert(data.msg[0]);
		else
			this.setState({ splitStep: 2, newpages: data.data.newList });
	}

}

export default withRouter(withAlert()(EditSheet));