import React from 'react';
import { Link , Redirect } from 'react-router-dom';
import Util from '../../Util';
import style from './Style.module.css';
import {Container} from 'react-grid-system';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';


class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data:{
				user:{
					name:'',
					perm:[],
				},
			},
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/moresheetStaff/navigation',{});
		if(!data.success)
			this.props.history.push('/');
		else {
			this.setState({data:data.data})
		}
	}

	render() {
		return (
			<div className={style.sheetPage}>
				<Container>
					<div className={style.header}>Staff Home</div>
					<div className={style.subheader}>Staff : {this.state.data.user.name}</div>
					<hr />
					<div className={style.subheader}>สิทธิ์ที่มี :</div>
					{this.state.data.user.perm.includes(1)&&
						<React.Fragment>
							<Link to='/staff/sheetInfo' className={style.linkBox}><div>แก้ไขข้อมูลชีท</div></Link>
							<Link to='/staff/verifySheet' className={style.linkBox}><div>ตรวจสอบและเผยแพร่ชีท</div></Link>
							<Link to='/staff/downloadapprovedcover' className={style.linkBox}><div>Download หน้าปกชีท</div></Link>
							<Link to='/staff/report' className={style.linkBox}><div>Report จากผู้ซื้อ</div></Link>
							<Link to='/staff/author' className={style.linkBox}><div>ฐานข้อมูลผู้ขาย</div></Link>
						</React.Fragment>
					}
					{this.state.data.user.perm.includes(2)&&
						<React.Fragment>
							<Link to='/staff/payment' className={style.linkBox}><div>โอนเงินให้ผู้ขาย</div></Link>
							<Link to='/staff/stat' className={style.linkBox}><div>ข้อมูลทางสถิติต่างๆ</div></Link>
						</React.Fragment>
					}
					
				</Container>
			</div>
		);
	}
	
	componentWillUnmount () {
		
	}
}

export default withRouter(withAlert()(EditSheet));