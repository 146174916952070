import React from 'react';
import { Redirect } from 'react-router-dom';

class Singhdrive extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			
		};
	}

	render() {
		return (
			<React.Fragment>
				<Redirect to='/secretshop?9999' />
			</React.Fragment>
		);
	}
}

export default Singhdrive;