import React from 'react';
import Util from '../../Util';
import Nav from '../../Components/Nav';
import Footer from '../../Components/Footer';
import style from './Style.module.css';
import { Link , Redirect } from 'react-router-dom';
import {Container, Col, Row, Hidden, Visible} from 'react-grid-system';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import SellSheet from '../../Components/ShopSheet';
import star from '../../asset/star.png';

class Sell extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			data:{
				user:{
					infoEnter:true,
				},
				group:{
					_id:'',
					name:'',
					detail:'',
					sheets:[],
				},
				sheets:[]
			},
		};
	}
	
	async componentDidMount() {
		window.history.pushState({}, '', '');
		window.onpopstate = (event)=>{};
		var id = this.props.location.search.substring(1).split('&')[0];
		var data = await Util.sendPost('/api/public/getGroupInfo',{groupID:id});
		if(data.success) {
			this.setState({data:data.data});
		}
		else
			this.props.history.push('/');
		window.onpopstate = (event)=>{window.close()};
	}

	render() {
		return (
			<div className={style.shopPage}>
				<Nav current='shop' user={this.state.data.user}/>
				<div className={style.blankTop} />
				<Container>
					<Hidden xs sm>
						<div className={style.sheetInfoWrapper}>
							<div className={style.sheetLeft}>
								<img className={style.sheetCover} src={process.env.REACT_APP_GROUP_URL+this.state.data.group._id+'/cover.jpg'} alt='cover'></img>
							</div>
							<div className={style.sheetRight}>
								<div className={style.sheetname}>{this.state.data.group.name}</div>
								<div className={style.cusHR} />
								<div className={style.cartPanel}>
									<div className={style.price}>
										<div className={style.infoDataPrice}>
											{this.price()}
										</div>
										<div className={style.infoDataPriceCur}>
											บาท
										</div>
									</div>
									<div className={style.addCart} onClick={()=>this.addCart(this.state.data.group._id)}>
										เพิ่มทั้งหมดลงตะกร้า
									</div>
								</div>
								<div className={style.infoHead}>
									รายละเอียดเพิ่มเติม
								</div>
								<div className={style.infoData}>
									{this.nl2br(this.state.data.group.detail)}
								</div>
							</div>
						</div>
					</Hidden>
					<Visible xs sm>
						<img className={style.sheetCover+' '+style.mobile} src={process.env.REACT_APP_GROUP_URL+this.state.data.group._id+'/cover.jpg'} alt='cover'></img>
						<div className={style.sheetname}>{this.state.data.group.name}</div>
						<div className={style.cusHR} />
						<div className={style.cartPanel}>
							<div className={style.price}>
								<div className={style.infoDataPrice}>
									{this.price()}
								</div>
								<div className={style.infoDataPriceCur}>
									บาท
								</div>
							</div>
							<div className={style.addCart} onClick={()=>this.addCart(this.state.data.group._id)}>
								เพิ่มทั้งหมดลงตะกร้า
							</div>
						</div>
						<div className={style.infoHead}>
							รายละเอียดเพิ่มเติม
						</div>
						<div className={style.infoData}>
							{this.nl2br(this.state.data.group.detail)}
						</div>
					</Visible>
					<div className={style.cusHR} />
					<div className={style.headerExam}>ชีทในแพคเกจ</div>
					<div className={style.sheetWrapper}>
						{this.renderStuff()}
					</div>
				</Container>
				<Footer />
			</div>
		);
	}
	
	padder=(val)=> {
		if(val<10)
			val='0'+val.toString();
		return val;
	}
	
	price=()=> {
		return this.state.data.sheets.reduce((total,sheet)=>{
			return total + sheet.price;
		},0);
	}
	
	
	nl2br = (text) => {
		return text.split('\n').map((item, key) => {
			return <React.Fragment key={key}>{item}<br/></React.Fragment>
		});
	}
	
	renderStuff=()=>{
		return this.state.data.sheets.map((sheet) => {
				return (
					<SellSheet sheet={sheet} key={sheet._id} store={{}} addCart={this.addCartSheet}>
					</SellSheet>
				)
			}
		);
	}
	
	addCart=async()=>{
		var comp=true;
		for(var i=0;i<this.state.data.sheets.length;i++) {
			var id = this.state.data.sheets[i]._id;
			var data = await Util.sendPost('/api/public/ownSheet',{id});
			if(data.success) {
				var res = await Util.addCartGroup(id,this.state.data.group.name);
			}
			else
				comp=false;
		}
		if(!comp)
			this.props.alert.show('คุณเป็นเจ้าของบางชีทอยู่แล้ว ชีทเหล่านั่นจะไม่ถูกเพิ่มลงตะกร้า',{type: 'info'});
		else
			this.props.alert.show('เพิ่มชีททั้งหมดลงตะกร้าสำเร็จ',{type: 'success'});
	}
	
	addCartSheet=async(id)=>{
		var data = await Util.sendPost('/api/public/ownSheet',{id});
		if(data.success) {
			var res = await Util.addCart(id);
			if(res)
				this.props.alert.show('เพิ่มชีทลงตะกร้าสำเร็จ',{type: 'success'});
			else
				this.props.alert.show('มีชีทนี้ในตะกร้าอยู่แล้ว',{type: 'error'});
		}
		else
			this.props.alert.show('คุณเป็นเจ้าของชีทนี้อยู่แล้ว',{type: 'error'});
	}

}

export default withRouter(withAlert()(Sell));