import React from 'react';
import { Link , Redirect } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import Util from '../../Util';
import style from './Style.module.css';
import './style.css';

const category=['Others','midterm','final'];

class EditSheet extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			redirect:false,
			data:{
				user:{},
				stores:[]
			},
			sheet:false,
			store:-1,
			count:0,
			rejectReason:''
		};
	}
	
	async componentDidMount() {
		var data = await Util.sendPost('/api/moresheetStaff/getVerifySheet',{});
		if(!data.success)
			await this.setState({redirect:true});
		await this.setState({data:data.data});
		await this.setState({store:data.data.stores[0].code});
		await this.getQueueLength(this.state.store);
	}

	render() {
		return (
			<div className={style.sheetPage}>
				{this.state.redirect && <Redirect to='/staff/' />}
				<div className={style.container}>
					<div className={style.header}>ตรวจสอบชีทก่อนเผยแพร่</div>
					<div className={style.explain}>เลือกมหาวิทยาลัยเพื่อตรวจสอบชีท</div>
					<hr />
					
					<SelectSearch className='select-search-box-verifySheet' options={this.genUniOptions()} renderOption={(option) => {return (option.name[1]==='0'?<div style={{backgroundColor:"white", color:"black"}}>{option.name}</div>:<div style={{backgroundColor:"black", color:"white"}}>{option.name}</div>)}} value={this.state.store.toString()} onChange={(value)=>this.setUni(value)} />
					<div className={style.queueLabel}>จำนวนชีทรอการตรวจสอบ: ~{this.state.count}</div>
					<hr />
					{!this.state.sheet?
							<React.Fragment>
							<div onClick={()=>this.requestSheet()} className={style.requestButton}>Get a sheet to verify</div>
							<div onClick={()=>this.resetSheet()} className={style.requestButton}>Reset stalled sheet in queue</div>
							</React.Fragment>
						:
							<React.Fragment>
								<div className={style.reject}>
									<div className={style.rejectHeader}>ข้อความเพิ่มเติมจากผู้เขียน :</div>
									<div className={style.rejectReason}>
										{this.nl2br(this.state.sheet.message)}
									</div>
								</div>
								<div className={style.label}>นามปากกา</div>
								<a rel='noopener noreferrer' href={'/sellerProfile/'+this.state.sheet.author.toString()} target='_blank' className={style.Link}>{this.state.sheet.authorCode}</a>
								<hr />
								
								<div className={style.label}>ชื่อ Facebook ของผู้เขียน (อาจถูกเปลี่ยนเป็นชื่อจริงแล้ว)</div>
								<div className={style.data}>{this.state.sheet.authorName}</div>
								<hr />
								
								<div className={style.label}>ชื่อชีท</div>
								<div className={style.data}>{this.state.sheet.name}</div>
								<hr />
								
								<div className={style.label}>ประเภท</div>
								<div className={style.data}>{category[this.state.sheet.category]}</div>
								<hr />
								
								<div className={style.label}>ภาคเรียน</div>
								<div className={style.data}>{this.state.sheet.semester+' / '+this.state.sheet.year}</div>
								<hr />
								
								<div className={style.label}>รายละเอียดคลาส</div>
								<div className={style.data}>{this.nl2br(this.state.sheet.classInfo)}</div>
								<hr />
								
								<div className={style.label}>รายละเอียดเนื้อหา</div>
								<div className={style.data}>{this.nl2br(this.state.sheet.detail)}</div>
								<hr />
								
								<div className={style.label}>ราคา</div>
								<div className={style.data}>{this.state.sheet.price+' Baht'}</div>
								<hr />
								
								<div className={style.label}>รูปปก ให้ reject ถ้าไม่มี</div>
								<a href={''} target='_blank' rel='noopener noreferrer'><img alt='' src={process.env.REACT_APP_SHEET_URL+this.state.sheet._id+'/cover.jpg'} className={style['coverImage']} /></a>
								<hr />
								
								<div className={style.label}>ตัวอย่างชีท : {this.state.sheet.examNum} (If no images below please reject)</div>
								{this.renderSamples()}
								<hr />
								
								<div className={style.label}>ไฟล์ชีท</div>
								{
									this.state.sheet.pdf.success?
											<a rel='noopener noreferrer' href={this.state.sheet.pdf.data} target='_blank' className={style.Link}>Open File</a>
										:
											<div className={style.notFound}>File not found , please reject this sheet along with File not Found Reason</div>
								}
								<hr />
								
								<div onClick={()=>this.confirmAccept()} className={style.sendButton}>เผยแพร่</div>
								<textarea placeholder='Reasons to reject' className={style.textArea}  value={this.state.rejectReason} onChange={(event)=>{this.setState({rejectReason:event.currentTarget.value})}} />
								<div onClick={()=>this.confirmReject()} className={style.rejectButton}>ปฏิเสธด้วยเหตุผลด้านบน</div>
								
							</React.Fragment>
					}
				</div>
			</div>
		);
	}
	
	componentWillUnmount () {
		
	}
	
	getQueueLength = async(store) => {
		var data2 = await Util.sendPost('/api/moresheetStaff/getQueueLength',{store});
		if(!data2.success)
			await this.setState({redirect:true});
		await this.setState({count:data2.data});
	}
	
	requestSheet = async() => {
		var store=this.state.store;
		var data2 = await Util.sendPost('/api/moresheetStaff/requestReviewSheet',{store});
		console.log(data2);
		if(!data2.success)
			Util.alertError(data2.msg[1]);
		else
			await this.setState({sheet:data2.data});
	}

	resetSheet = async() => {
		var store=this.state.store;
		var data2 = await Util.sendPost('/api/moresheetStaff/requestResetSheet',{store});
		if(!data2.success)
			Util.alertError(data2.msg[1]);
		else
			Util.alertSuccess("Resetted, please click on get a sheet again",()=>{});
	}
	
	genUniOptions = () => {
		if(this.state.data.stores.length===0)
			return [{value: '0', name: ''}];
		return this.state.data.stores.map(({name,code,count}) => (
				{value:code.toString(),name:`(${count}) ` + name.join(' - '), highlight:count>0}
			)
		);
	}
	
	setUni = async(value) => {
		this.getQueueLength(parseInt(value.value));
		this.setState({store:parseInt(value.value)});
	}
	
	nl2br =(text)=> {
		return text.split('\n').map((item, key)=> {
			return (
				<span className={style.line} key={key}>
					{item}
				</span>)
		})
	}
	
	renderSamples = ()=> {
		var urls=[];
		for(var i=0;i<this.state.sheet.examNum;i++)
			urls.push(process.env.REACT_APP_SHEET_URL+this.state.sheet._id+'/ex'+(i+1).toString()+'.jpg');
		return urls.map((url) => (
				<a key={url} href={url} target='_blank' rel='noopener noreferrer'><img alt='' src={url} className={style.sampleImage} /></a>
			)
		);
	}
	
	confirmAccept = async() => {
		const options = {
			title: 'Accept',
			message: 'Sure to accept and publish this sheet?',
			buttons: [
			{
				label: 'Yes',
				onClick: async() => {
					var data = await Util.sendPost('/api/moresheetStaff/approveSheet',{sheetID:this.state.sheet._id});
					if(!data.success)
						Util.alertError(data.msg[1]);
					else
					{
						await this.setState({sheet:false});
						await this.getQueueLength(this.state.store);
					}
				}
			},
			{
				label: 'No',
				onClick: async() => {}
			}
			],
			closeOnEscape: false,
			closeOnClickOutside: false,
		};
		Util.alertConfirm(options);
	}
	
	confirmReject = async() => {
		const options = {
			title: 'Reject',
			message: 'Sure to reject this sheet with reasons: '+this.state.rejectReason,
			buttons: [
			{
				label: 'Yes',
				onClick: async() => {
					var data = await Util.sendPost('/api/moresheetStaff/rejectSheet',{
						sheetID:this.state.sheet._id,
						reasons:this.state.rejectReason
					});
					if(!data.success)
						Util.alertError(data.msg[1]);
					else
					{
						await this.setState({sheet:false});
						await this.getQueueLength(this.state.store);
					}
				}
			},
			{
				label: 'No',
				onClick: async() => {}
			}
			],
			closeOnEscape: false,
			closeOnClickOutside: false,
		};
		Util.alertConfirm(options);
	}
	
}

export default EditSheet;